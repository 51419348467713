import React, { useState, useEffect } from "react";

const CookiesBanner = () => {
    const [acceptedCookies, setAcceptedCookies] = useState(
        localStorage.getItem("acceptedCookies") === "true",
    );
    const [showBanner, setShowBanner] = useState(false); // State to control banner visibility

    const handleAcceptCookies = () => {
        localStorage.setItem("acceptedCookies", "true");
        setAcceptedCookies(true);
        setShowBanner(false); // Hide the banner after accepting cookies
    };

    const handleDeclineCookies = () => {
        localStorage.setItem("acceptedCookies", "false");
        setAcceptedCookies(false);
        setShowBanner(false); // Hide the banner after declining cookies
    };

    useEffect(() => {
        setShowBanner(!acceptedCookies); // Show the banner if cookies have not been accepted
    }, [acceptedCookies]);
    return (
        <div className={`cookies-banner ${showBanner ? "show" : ""}`}>
            <p>
                This website uses cookies to ensure you get the best experience
                on our website.{" "}
                <a
                    href="/privacy#cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "white" }}
                >
                    Check our cookies policy here
                </a>
            </p>
            <div className="cookies-actions">
                <button onClick={handleAcceptCookies}>Accept</button>
                <button onClick={handleDeclineCookies}>Decline</button>
            </div>
        </div>
    );
};

export default CookiesBanner;
