import { Suspense, lazy } from "react";

import { AuthGuard } from "src/auth/guard";
import DashboardLayout from "src/layouts/dashboard";
import { LoadingScreen } from "src/components/loading-screen";
import { Outlet } from "react-router-dom";

// OVERVIEW
const IndexPage = lazy(() => import("src/pages/dashboard/app"));

const OverviewAnalyticsPage = lazy(() =>
    import("src/pages/dashboard/analytics"),
);
const OverviewBankingPage = lazy(() => import("src/pages/dashboard/banking"));
const OverviewBookingPage = lazy(() => import("src/pages/dashboard/booking"));
const OverviewFilePage = lazy(() => import("src/pages/dashboard/file"));


// USER
const UserProfilePage = lazy(() => import("src/pages/dashboard/user/profile"));
const UserCardsPage = lazy(() => import("src/pages/dashboard/user/cards"));
const UserListPage = lazy(() => import("src/pages/dashboard/user/list"));
const UserAccountPage = lazy(() => import("src/pages/dashboard/user/account"));
const UserCreatePage = lazy(() => import("src/pages/dashboard/user/new"));
const UserEditPage = lazy(() => import("src/pages/dashboard/user/edit"));

// ACTIVITY
const ActivityListPage = lazy(() =>
    import("src/pages/dashboard/activity/list"),
);
const ActivityDetailsPage = lazy(() =>
    import("src/pages/dashboard/activity/details"),
);
const ActivityCheckoutPage = lazy(() =>
    import("src/pages/dashboard/activity/checkout"),
);
// HOTEL VIEW
const HotelListPage = lazy(() => import("src/pages/dashboard/hotel/list"));
const HotelEditPage = lazy(() => import("src/pages/dashboard/hotel/edit"));
const HotelDetailsPage = lazy(() =>
    import("src/pages/dashboard/hotel/details"),
);
const HotelCheckoutPage = lazy(() =>
    import("src/pages/dashboard/hotel/checkout"),
);

// FLIGHTS
const FlightHomePage = lazy(() => import("src/pages/dashboard/flight"));
const FlightCheckoutPage = lazy(() =>
    import("src/pages/dashboard/flight/checkout"),
);

// TRASNFERS
const TransferHomePage = lazy(() => import("src/pages/dashboard/transfer"));
const TransferCheckoutPage = lazy(() =>
    import("src/pages/dashboard/transfer/checkout"),
);

// CAR RENTAL
const CarRentalHomePage = lazy(() => import("src/pages/dashboard/carRental"));
const CarRentalCheckoutPage = lazy(() =>
    import("src/pages/dashboard/carRental/checkout"),
);

// CLIENT VIEW
const ClientViewPage = lazy(() => import("src/pages/dashboard/client-view"));
const ClientBookViewPage = lazy(() =>
    import("src/pages/dashboard/client-book-view"),
);
// PROVIDER VIEW
const ProviderViewPage = lazy(() => import("src/pages/dashboard/provider-view"));

// FILE MANAGER
const FileManagerPage = lazy(() => import("src/pages/dashboard/file-manager"));
// APP

// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() =>
    import("src/pages/dashboard/permission"),
);
// BLANK PAGE
const BlankPage = lazy(() => import("src/pages/dashboard/blank"));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
    {
        path: "dashboard",
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <IndexPage />, index: true },
            { path: "analytics", element: <OverviewAnalyticsPage /> },
            { path: "banking", element: <OverviewBankingPage /> },
            { path: "bookingDashboard", element: <OverviewBookingPage /> },
            { path: "file", element: <OverviewFilePage /> },
            {
                path: "user",
                children: [
                    { element: <UserProfilePage />, index: true },
                    { path: "profile", element: <UserProfilePage /> },
                    { path: "cards", element: <UserCardsPage /> },
                    { path: "list", element: <UserListPage /> },
                    { path: "new", element: <UserCreatePage /> },
                    { path: ":id/edit", element: <UserEditPage /> },
                    { path: "account", element: <UserAccountPage /> },
                ],
            },
            
            {
                path: "activity",
                children: [
                    { element: <ActivityListPage />, index: true },
                    { path: "list", element: <ActivityListPage /> },
                    {
                        path: ":productCode/:gdsprovider",
                        element: <ActivityDetailsPage />,
                    },
                    {
                        path: "checkout/:code",
                        element: <ActivityCheckoutPage />,
                    },
                ],
            },
            {
                path: "hotel",
                children: [
                    { element: <HotelListPage />, index: true },
                    { path: "list", element: <HotelListPage /> },
                    {
                        path: "hotelDetails/:gdsprovider/:code",
                        element: <HotelDetailsPage />,
                    },
                    { path: ":code/edit", element: <HotelEditPage /> },
                    {
                        path: "checkout/:code/:rateKey",
                        element: <HotelCheckoutPage />,
                    },
                ],
            },

            {
                path: "flight",
                children: [
                    { element: <FlightHomePage />, index: true },
                    {
                        path: "checkout/:offerId",
                        element: <FlightCheckoutPage />,
                    },
                ],
            },

            {
                path: "transfer",
                children: [
                    { element: <TransferHomePage />, index: true },
                    {
                        path: "checkout/:offerId",
                        element: <TransferCheckoutPage />,
                    },
                ],
            },

            {
                path: "carRental",
                children: [
                    { element: <CarRentalHomePage />, index: true },
                    {
                        path: "checkout/:offerId",
                        element: <CarRentalCheckoutPage />,
                    },
                ],
            },

            { path: "client-view", element: <ClientViewPage /> },
            { path: "provider-view", element: <ProviderViewPage /> },
            { path: "client-book-view", element: <ClientBookViewPage /> },
            { path: "file-manager", element: <FileManagerPage /> },
            { path: "permission", element: <PermissionDeniedPage /> },
            { path: "blank", element: <BlankPage /> },
        ],
    },
];
