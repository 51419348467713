import { useCallback, useEffect, useRef } from "react";

export function useDebouncedCallback(callback, delay) {
    const callbackRef = useRef(callback);
    const timeout = useRef(null);
    const debouncedCallback = useCallback(
        (...args) => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            timeout.current = setTimeout(() => {
                callbackRef.current(...args);
                timeout.current = null;
            }, delay);
        },
        [delay],
    );

    useEffect(() => {
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, []);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    return debouncedCallback;
}
