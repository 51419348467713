import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { parseISO, startOfDay } from "date-fns";
import { useForm, useWatch } from "react-hook-form";
import axiosInstance from "src/config/axiosInstance";
import { LOCAL_STORAGE_CURRENCY } from "src/constants";
import { useRouter } from "src/routes/hooks";
import { paths } from "src/routes/paths";
import { processFlightsOptions } from "src/utils";
import dayjs from "dayjs";
import { CustomersService } from "src/api/tz";

export const useBannerForm = (formSchema, project_id) => {
    const getDefaultStartDate = () => {
        return dayjs().add(2, "month").startOf("day");
    };

    // Function to get the default end date based on the start date
    const getDefaultEndDate = (startDate) => {
        return startDate.add(3, "day").endOf("day"); // Adds 3 days to the start date
    };

    const methods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            cities: [""],
            startDate: [getDefaultStartDate()],
            endDate: [getDefaultEndDate(getDefaultStartDate())],
        },
        mode: "onChange",
    });
    const { updateCustomerDetails } = CustomersService;

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
        reset,
    } = methods;
    const router = useRouter();
    const citiesWatch = useWatch({
        control,
        name: "cities",
        defaultValue: [""],
    });
    const { cities, startDate, endDate } = getValues();
    const {
        user,
        loginWithPopup,
        loginWithRedirect,
        handleRedirectCallback,
        getAccessTokenSilently,
        isAuthenticated,
    } = useAuth0();
    const [customerPreferences, setCustomerPreferences] = useState();
    const [questionnaireData, setQuestionnaireData] = useState({});
    const [splashScreenVisible, setSplashScreenVisible] = useState(false);
    const [allCities, setAllCities] = useState();
    const [currentCityInput, setCurrentCityInput] = useState("");
    const [projectData, setProjectData] = useState({});
    const [cityInputs, setCityInputs] = useState(cities);
    const [showQuestionnaire, setShowQuestionnaire] = useState(false);
    const [checkoutInputs, setCheckoutInputs] = useState(
        Array(cityInputs.length).fill(""),
    );

    //[GO]: Esta funcion se va a ejecutar aunque se haga login con el boton del header.
    useEffect(() => {
        if (!user) return;
        if (localStorage.getItem("leadCreated")) return;
        updateCustomerDetails(
            {
                country: localStorage.getItem("country"),
                email: user.email,
                phone: localStorage.getItem("phone-number"),
                country: localStorage.getItem("country"),
                company: "66747877d47803843cb9161b",
                user: "66747cdf4eb37c7bcfcb57a7",
                responsible: "66747cdf4eb37c7bcfcb57a7",
                userName: "Barun",
                cities: cities,
            },
            1,
            1000,
        )
            .then((response) => {
                localStorage.setItem("leadCreated", true);
            })
            .catch((error) => {
                console.error("An error occurred:", error);
            });
    }, [user]);

    const [flightsOptions, setFlightsOptions] = useState({
        departureCity: {
            name: "",
            code: "",
        },
        departureDate: "",
        class: "Economy",
        passangers: {
            adults: 1,
            child: 0,
            infants: 0,
        },
        directFlight: false,
    });

    const handleCheckinChange = (value, rowIndex) => {
        const datevalue = value.target.value;
        const name = value.target.name;
        const date = new Date(datevalue);

        if (date) {
            const newDateInputs = [...startDate];
            newDateInputs[rowIndex] = date;

            setValue(name, newDateInputs[rowIndex]);
        }
    };

    const handleCheckoutChange = (value, rowIndex) => {
        const datevalue = value.target.value;
        const date = new Date(datevalue);

        const name = value.target.name;

        if (date) {
            const newDateOutputs = [...endDate];
            newDateOutputs[rowIndex] = date;

            setValue(name, newDateOutputs[rowIndex]);
        }
    };

    const extractProperties = (cityArray) => {
        return cityArray.map(({ _id, destinationName }) => ({
            _id,
            destinationName,
        }));
    };

    const createTrip = async (data) => {
        console.log("createTrip", data);
        console.log("user", user);
        const phone = localStorage.getItem("phone-number");
        const customerName = user?.name || localStorage.getItem("name");

        data.customerPhone = phone;
        data.customerName = customerName;
        data.customer = user?.email;
        data.customerFrom = "adams";
        data.company = "66747877d47803843cb9161b";
        data.responsible = "66747cdf4eb37c7bcfcb57a7";
        data.currency =
            window.localStorage.getItem(LOCAL_STORAGE_CURRENCY) || "EUR";
        try {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_HOST_API}/trips/new`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );

            // const tripId = response.data._id || "6511d8d89cf9ce5a7ea5f04b";
            // createItineraryForTrip(tripId);
            if (response) {
                setProjectData(response.data);
                setFlightsOptions((prev) => {
                    const responseDate = parseISO(response.data.startDate[0]);
                    const date = startOfDay(responseDate);
                    return {
                        ...prev,
                        departureDate: date,
                    };
                });
            } else {
                console.error("Failed to create trip with the input data");
            }
        } catch (error) {
            console.error(
                "Failed to create trip",
                error.response ? error.response.data : error.message,
            );
        }
    };

    const getMinStartDate = (index) => {
        if (index === 0) {
            return new Date().toISOString().split("T")[0];
        }
        const previousEndDate = getValues(`endDate[${index - 1}]`);
        return previousEndDate[index - 1] || new Date();
    };

    const getMaxCheckoutDate = (index) => {
        const startDateValue = getValues(`startDate.${index}`);
        if (startDateValue) {
            const startDate = new Date(startDateValue);
            const maxCheckoutDate = new Date(startDate);
            maxCheckoutDate.setDate(startDate.getDate() + 10);
            return maxCheckoutDate;
        }
        return "";
    };

    const handleExplorePlansClick = async (data) => {
        await createTrip(data);
    };

    const handleAddCityRow = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const newStartDate = getDefaultStartDate();
        const newEndDate = getDefaultEndDate(newStartDate);

        setValue("cities", [...cities, ""]);
        setValue("startDate", [
            ...startDate.map((date) => dayjs(date).add(1, "day")),
            newStartDate,
        ]);
        setValue("endDate", [
            ...endDate.map((date) => dayjs(date).add(1, "day")),
            newEndDate,
        ]);
        setCurrentCityInput("");
        setCityInputs((prevInputs) => [...prevInputs, ""]);
    };

    const handleRemoveCityRow = (event, cityId, startDateId, endDateId) => {
        event.preventDefault();
        event.stopPropagation();
        if (cities.length > 1) {
            const newCityRows = [...cities];
            const newStartDateRows = [...startDate];
            const newEndDateRows = [...endDate];
            const filteredCitiesArr = newCityRows.filter(
                (obj, index) => `${index} + ${obj}` !== cityId,
            );
            const filteredStartDatesArr = newStartDateRows.filter(
                (obj, index) => `${index} + ${obj}` !== startDateId,
            );
            const filteredEndDatesArr = newEndDateRows.filter(
                (obj, index) => `${index} + ${obj}` !== endDateId,
            );
            setValue("cities", filteredCitiesArr);
            setValue("startDate", filteredStartDatesArr);
            setValue("endDate", filteredEndDatesArr);
            setCityInputs(filteredCitiesArr);
        }
    };

    useEffect(() => {
        if (!isAuthenticated) {
            getAccessTokenSilently()
                .then((token) => {
                    localStorage.setItem("token", token);
                })
                .catch((error) => {
                    console.error("An error occurred:", error);
                });
        }

        const parseRedirect = async () => {
            try {
                if (!window.location.search.includes("code=")) return;
                const result = await handleRedirectCallback();

                if (result.appState?.projectFormattedData) {
                    const projectFormattedData = JSON.parse(
                        result.appState.projectFormattedData,
                    );

                    setValue("cities", projectFormattedData.cities);
                    setValue("startDate", projectFormattedData.checkin);
                    setValue("endDate", projectFormattedData.checkout);
                    await handleExplorePlansClick(projectFormattedData);
                    handleOpenQuestionnaire();
                }
            } catch (error) {}
        };
        parseRedirect();
    }, []);

    const onSubmit = async (data, event) => {
        event.preventDefault();
        const userCountry = localStorage.getItem("country");
        let projectFormattedData = {
            cities: data.cities?.map((city) => city?.split(",")[0]),
            userCountry,
            checkin: data.startDate,
            checkout: data.endDate,
            adults: 2,
            rooms: 1,
            projectId: project_id,
        };

        setProjectData(projectFormattedData);
        /* if (!user?.email) {
            localStorage.setItem(
                "projectData",
                JSON.stringify(projectFormattedData),
            );
            const serializedData = JSON.stringify(projectFormattedData);
            await loginWithRedirect({
                appState: {
                    returnTo: "/",
                    projectFormattedData: serializedData,
                },
            });
            return;
        } else { */
        //}
        await handleExplorePlansClick(projectFormattedData);

        try {
            // Show the questionnaire dialog before proceeding
            handleOpenQuestionnaire();
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    // Function to handle opening the questionnaire dialog
    const handleOpenQuestionnaire = () => {
        setShowQuestionnaire(true);
    };

    // Function to handle closing the questionnaire dialog
    const handleCloseQuestionnaire = () => {
        setShowQuestionnaire(false);
    };

    const handleQuestionnaireSubmit = () => {
        handleCreateItinerary({
            ...projectData,
            ...questionnaireData,
        });
        handleCloseQuestionnaire();
    };

    const handleCreateItinerary = async (project) => {
        setSplashScreenVisible(true);
        window.scroll({ top: 0, left: 0, behavior: "instant" });
        projectData.projectId = project._id;
        projectData.userName = "web_user";

        const processedFlightsOptions = await processFlightsOptions(
            flightsOptions,
            projectData,
        );
        projectData.flights = processedFlightsOptions;

        try {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_HOST_API}/createProjectItinerary`,
                projectData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            setSplashScreenVisible(false);
            const companyId = sessionStorage.getItem("companyId");

            if (response) {
                const agentPath = paths.clientView(projectData?._id, companyId);

                router.push(agentPath);
            } else {
                console.error("Failed to create proposal");
            }
        } catch (error) {
            setSplashScreenVisible(false);
            console.error("An error occurred while creating proposal", error);
        }
    };

    const [isManualSelection, setIsManualSelection] = useState(false);
    useEffect(() => {
        if (citiesWatch[0]) {
            setIsManualSelection(true);
            return;
        }
        setIsManualSelection(false);
    }, [citiesWatch]);

    const handleCreateTour = async (tour) => {
        const cities = [];
        const daysPerCity = [];
        const citiesTimezones = [];
        tour.destinations.forEach((dest, index) => {
            cities[index] = dest.cityKey;
            daysPerCity[index] = dest.days;
            citiesTimezones[index] = dest.timeZone;
        });
        const projectFormattedData = {
            cities,
            isATour: true,
            daysPerCity,
            citiesTimezones,
            checkin: tour?.checkin,
            // projectId: project_id,
        };
        setProjectData(projectFormattedData);
        if (!user?.email) {
            loginWithPopup();
            return;
        } else {
            await handleExplorePlansClick(projectFormattedData);
        }
        try {
            // Show the questionnaire dialog before proceeding
            handleOpenQuestionnaire();
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const resetSelection = () => {
        reset({
            cities: [""],
            startDate: [],
            endDate: [],
        });
    };

    return {
        errors,
        cities,
        startDate,
        endDate,
        questionnaireData,
        splashScreenVisible,
        allCities,
        currentCityInput,
        projectData,
        cityInputs,
        showQuestionnaire,
        checkoutInputs,
        methods,
        flightsOptions,
        isManualSelection,

        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        setQuestionnaireData,
        setSplashScreenVisible,
        setAllCities,
        setCurrentCityInput,
        setProjectData,
        setCityInputs,
        setShowQuestionnaire,
        setCheckoutInputs,
        setCustomerPreferences,
        handleCheckinChange,
        handleCheckoutChange,
        extractProperties,
        createTrip,
        getMinStartDate,
        getMaxCheckoutDate,
        handleExplorePlansClick,
        handleAddCityRow,
        handleRemoveCityRow,
        onSubmit,
        handleOpenQuestionnaire,
        handleCloseQuestionnaire,
        handleQuestionnaireSubmit,
        handleCreateItinerary,
        setFlightsOptions,
        handleCreateTour,
        resetSelection,
    };
};
