export function numberToOrdinal(number) {
    const ordinals = [
        "zero",
        "first",
        "second",
        "third",
        "fourth",
        "fifth",
        "sixth",
        "seventh",
        "eighth",
        "ninth",
        "tenth",
        "eleventh",
        "twelfth",
        "thirteenth",
        "fourteenth",
        "fifteenth",
        "sixteenth",
        "seventeenth",
        "eighteenth",
        "nineteenth",
        "twentieth",
        "twenty-first",
        "twenty-second",
        "twenty-third",
        "twenty-fourth",
        "twenty-fifth",
        "twenty-sixth",
        "twenty-seventh",
        "twenty-eighth",
        "twenty-ninth",
        "thirtieth",
        "thirty-first",
        "thirty-second",
        "thirty-third",
        "thirty-fourth",
        "thirty-fifth",
        "thirty-sixth",
        "thirty-seventh",
        "thirty-eighth",
        "thirty-ninth",
        "fortieth",
        "forty-first",
        "forty-second",
        "forty-third",
        "forty-fourth",
        "forty-fifth",
        "forty-sixth",
        "forty-seventh",
        "forty-eighth",
        "forty-ninth",
        "fiftieth",
    ];

    if (number < ordinals.length) return ordinals[number];

    const tens = [
        "",
        "",
        "twentieth",
        "thirtieth",
        "fortieth",
        "fiftieth",
        "sixtieth",
        "seventieth",
        "eightieth",
        "ninetieth",
    ];
    const ones = [
        "",
        "first",
        "second",
        "third",
        "fourth",
        "fifth",
        "sixth",
        "seventh",
        "eighth",
        "ninth",
    ];

    if (number < 100) {
        return (
            tens[Math.floor(number / 10)] +
            (number % 10 ? "-" + ones[number % 10] : "")
        );
    }

    const hundred = Math.floor(number / 100);
    const remainder = number % 100;

    const hundredsWord = ordinals[hundred] + " hundred";
    if (remainder === 0) {
        return hundredsWord + "th";
    } else {
        return hundredsWord + " and " + numberToOrdinal(remainder);
    }
}
