import { m } from "framer-motion";
// @mui
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// routes
import { paths } from "src/routes/paths";
import { useRouter } from "src/routes/hooks";
// hooks
import { useMockedUser } from "src/hooks/use-mocked-user";
// auth
import { useAuthContext } from "src/auth/hooks";
// components
import { varHover } from "src/components/animate";
import { useSnackbar } from "src/components/snackbar";
import CustomPopover, { usePopover } from "src/components/custom-popover";
import { useLocales } from "src/locales";

// ----------------------------------------------------------------------
{
    /* {
    label: 'Profile',
    linkTo: paths.dashboard.user.profile,
  },*/
}

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const router = useRouter();

    const { user } = useMockedUser();

    const { logout } = useAuthContext();

    const { enqueueSnackbar } = useSnackbar();

    const popover = usePopover();

    const { t } = useLocales();

    const userName = sessionStorage.getItem("userName");
    const companyName = sessionStorage.getItem("companyName");

    const OPTIONS = [
        {
            label: t("Settings"),
            linkTo: paths.dashboard.user.account,
        },
    ];

    const handleLogout = async () => {
        try {
            // HDY: Limpio local y session storage antes del logout
            localStorage.clear();
            sessionStorage.clear();
            await logout();
            popover.onClose();
            router.replace("/");
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Unable to logout!", { variant: "error" });
        }
    };

    const handleClickItem = (path) => {
        popover.onClose();
        router.push(path);
    };

    return (
        <>
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                onClick={popover.onOpen}
                sx={{
                    width: 40,
                    height: 40,
                    background: (theme) => alpha(theme.palette.grey[500], 0.08),
                    ...(popover.open && {
                        background: (theme) =>
                            `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    }),
                }}
            >
                <Avatar
                    src={user?.photoURL}
                    alt={user?.displayName}
                    sx={{
                        width: 36,
                        height: 36,
                        border: (theme) =>
                            `solid 2px ${theme.palette.background.default}`,
                    }}
                />
            </IconButton>

            {/* (HDY) Se pierde el dato del user auth entonces lo reemplazo por datos del sessionStorage */}
            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                sx={{ width: 200, p: 0 }}
            >
                <Box sx={{ p: 2, pb: 1.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {/* {user?.displayName} */}
                        {userName !== null ? userName : ""}
                    </Typography>

                    <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                        noWrap
                    >
                        {/* {user?.email} */}
                        {companyName !== null ? companyName : ""}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: "dashed" }} />

                <Stack sx={{ p: 1 }}>
                    {OPTIONS.map((option) => (
                        <MenuItem
                            key={option.label}
                            onClick={() => handleClickItem(option.linkTo)}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: "dashed" }} />

                <MenuItem
                    onClick={handleLogout}
                    sx={{
                        m: 1,
                        fontWeight: "fontWeightBold",
                        color: "error.main",
                    }}
                >
                    {t("Logout")}
                </MenuItem>
            </CustomPopover>
        </>
    );
}
