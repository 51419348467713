export const storageKeys = {
    TOKEN: "accessToken",
    USER_ID: "userId",
    COMPANY_ID: "companyId",
    CURRENCY: "currency",
    LOGO: "logo",
    COMPANY_NAME: "companyName",
    APPLY_TAX: "applicableTax",
    PAYMENT_METHODS: "paymentMethods",
    HOTELS_FEE: "hotelsFee",
    ACTIVITIES_FEE: "activitiesFee",
    TRANSFERS_FEE: "transfersFee",
    CARS_FEE: "carsFee",
    FLIGTHS_FEE: "flightsFee",
    PACKAGE_FEE: "packagesFee",
    USER: "user",
    SUBSCRIPTION_PLAN: "subscriptionPlan",
};

console.log(storageKeys.USER_ID);

export const setItem = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

export const getItem = (key) => {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
};

export const removeItem = (key) => {
    sessionStorage.removeItem(key);
};

export const clearSessionStorage = () => {
    sessionStorage.clear();
};
