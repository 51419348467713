import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
// @mui
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// ----------------------------------------------------------------------

export default function RHFSearchAutocomplete({
    name,
    label,
    placeholder,
    helperText,
    handleExtraChange,
    handleExtraClick,
    inputStyle,
    // renderOptionStyle, // Add a new prop for renderOption styling
    ...other
}) {
    const { control, setValue } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <Autocomplete
                    {...field}
                    onChange={(event, newValue) =>
                        setValue(name, newValue, { shouldValidate: true })
                    }
                    renderInput={(params) => (
                        <TextField
                            label={label}
                            placeholder={placeholder}
                            error={!!error}
                            helperText={error ? error?.message : helperText}
                            InputProps={{
                                style: inputStyle, // Apply styles to the input
                            }}
                            {...params}
                            onChange={(e) => {
                                handleExtraChange(e?.target?.value);
                            }}
                            onClick={(e) => {
                                handleExtraClick(e?.target?.value);
                            }}
                        />
                    )}
                    {...other}
                />
            )}
        />
    );
}

RHFSearchAutocomplete.propTypes = {
    helperText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    inputStyle: PropTypes.object, // Additional styles for the input
};
