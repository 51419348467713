import React, { useEffect, useMemo, useRef, useState } from "react";
import BannerFormNew from "./BannerFormNew";
import { ContactModal } from "./ContactModal";

const BannerTable = () => {
    return (
        <div className="flex justify-center relative px-[20px] pb-[68px] md:pb-[121px] ">
            <div className="max-w-[1266px] w-full bg-white rounded-[20px] px-[16px] pt-[26px] md:pl-[24px] md:pr-[25px] md:pb-[28px] pb-[34px]">
                {/* tabs area */}

                {/*} <div className="flex gap-[23px] banner-tab-area items-center text-sm text-[#69696E] font-normal">
                    <button
                        // onClick={() => setIsBusiness(true)}
                        className={`cursor-default ${isBusiness ? "active" : ""}`}
                    >
                        Where would you like to travel?
                    </button>
                </div>*/}
                <BannerFormNew />
            </div>
            <ContactModal />
        </div>
    );
};


export default BannerTable;
