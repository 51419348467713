export const travelQuotes = [
    "The world is waiting to be discovered by you!",
    "Every journey is a new adventure filled with joy.",
    "Pack your dreams and go explore!",
    "Travel fills you with happiness and new experiences.",
    "The adventure begins with the first step out of the door.",
    "Every destination has a happy story to tell.",
    "Traveling is the best way to create unforgettable memories.",
    "Explore, dream, and discover!",
    "Happiness is found in the most unexpected corners of the world.",
    "Every new place brings new smiles and joys.",
    "Venture out and find joy in every corner!",
    "The world is full of wonderful places waiting for you.",
    "Traveling is a festival of discoveries and joy!",
    "Adventure and happiness go hand in hand when you travel.",
    "Discover the world and find happiness in every step!",
    "Travel gives you the chance to live magical moments.",
    "Every journey is a celebration of life and joy.",
    "Happiness is the best travel companion!",
    "Exploring the world is finding happiness in every corner.",
    "Start your journey and find joy in every moment!",
];