import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Logo from "src/components/logo";
import userLogo from "./assets/userLogo.svg";
import "react-modern-drawer/dist/index.css";
import menu from "../PlanTopBar/assets/menu.svg";
import { CurrencySelector } from "./CurrencySelector";
import { TollFreeNumber } from "src/components/phone-number/TollFreeNumber";

const HomeTopBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
    };
    const handleResize = () => {
        if (window.innerWidth >= 1024) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        // Add event listener when the component mounts
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const navigateToTop = () => {
        window.scrollTo(0, 0);
    };

    const userButton = (
        <div className="hidden lg:flex items-center">
            {!isAuthenticated ? (
                <button
                    onClick={() => loginWithRedirect()}
                    className="btn-red-gradient"
                >
                    Sign in
                </button>
            ) : (
                <>
                    <div className="flex items-center">
                        <img className="w-[20px] mr-2" src={userLogo} alt="" />
                        <p className="mr-2 text-sm">{user.name}</p>
                    </div>
                    <button
                        onClick={() =>
                            logout({ returnTo: window.location.origin })
                        }
                        className="btn-red-gradient"
                    >
                        Log out
                    </button>
                </>
            )}
        </div>
    );

    const items = (
        <ul className="flex flex-col lg:flex-row gap-4 items-center lg:gap-[32px] text-lg lg:text-xl text-main-black nav-items-wrap">
            <li>
                <a href="/#about" title="About Travelandz">
                    Private Jet
                </a>
            </li>
            <li>
                <a href="/#about" title="About Travelandz">
                    Concierge
                </a>
            </li>
            <li>
                <a href="/#discover" title="Famous destinations">
                    Travel Agencies
                </a>
            </li>
            <li>
                <a href="/#about" title="About Travelandz">
                    Destinations
                </a>
            </li>
            <li>
                <a href="/#contact" title="Contact us">
                    About us
                </a>
            </li>
        </ul>
    );

    return (
        <div className="pb-[100px]">
            <div className="w-full fixed top-0 left-0 right-0 z-50 bg-[#FBFBFB]">
                <div className="container mx-auto my-0">
                    <div className="flex justify-between items-center py-[10px]">
                        <Logo
                            sx={{ mt: 1, ml: 4, mb: 0, cursor: "pointer" }}
                            onClick={navigateToTop}
                        />
                        <div className="flex gap-4 items-center">
                            <TollFreeNumber />
                            <CurrencySelector />
                            {userButton}
                            <div
                                onClick={toggleDrawer}
                                className="block lg:hidden"
                            >
                                <button className="group relative p-2">
                                    <img src={menu} alt="" />
                                    <div className="absolute opacity-0 group-hover:opacity-100 transition-all pointer-events-none group-hover:pointer-events-auto w-[200px] py-4 rounded-lg top-full left-full z-30 -translate-x-full shadow-2xl border bg-[#FBFBFB]">
                                        <div>
                                            {items}
                                            <div className="flex justify-center mt-4">
                                                {isAuthenticated && (
                                                    <p>{user.name}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeTopBar;
