import { axiosInstance } from "src/config/axiosInstance";

class ItineraryService {
    // static async updateTask(taskId, updateData) {
    //   try {
    //     const response = await axiosInstance.put(
    //       `/tasks/update/${taskId}`,
    //       updateData
    //     );
    //     return response.data;
    //   } catch (error) {
    //     console.error("An error occurred while updating the task:", error);
    //     throw error;
    //   }
    // }
    static async updateItineraryProducts(itineraryId, updateData) {
        try {
            const response = await axiosInstance.put(
                `/itineraries/products/edit/${itineraryId}`,
                updateData,
            );
            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while updating the Itinerary:",
                error,
            );
            throw error;
        }
    }

    static async updateItineraryHotelSelected(itineraryId, updateData) {
        try {
            const response = await axiosInstance.put(
                `/itineraries/hotelselected/edit/${itineraryId}`,
                updateData,
            );
            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while updating the Itinerary:",
                error,
            );
            throw error;
        }
    }

    static async updateItinerary(itineraryId, updateData) {
        try {
            const response = await axiosInstance.put(
                `/itineraries/edit/${itineraryId}`,
                updateData,
            );
            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while updating the Itinerary:",
                error,
            );
            throw error;
        }
    }

    static async isItineraryCreated(projectId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/isProjectItineraryCreated/${projectId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching itinerary details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async setSelectedTicket(itineraryId, productCode, selectedTicket) {
        try {
            const response = await axiosInstance.post(
                `/itineraries/${itineraryId}/product/${productCode}/setSelectedTicket`,
                selectedTicket,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async DeleteProduct(itineraryId, productCode) {
        try {
            const response = await axiosInstance.delete(
                `/itineraries/${itineraryId}/remove/product/${productCode}`,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async setSelectedRoomRate(
        itineraryId,
        hotelCode,
        selectedRoom,
        selectedRate,
    ) {
        const data = {
            room: selectedRoom,
            roomRate: selectedRate,
        };
        try {
            const response = await axiosInstance.post(
                `/itineraries/${itineraryId}/hotel/${hotelCode}/setSelectedRoomRate`,
                data,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async DeleteTransfer(itineraryId, rateKey) {
        try {
            const response = await axiosInstance.delete(
                `/itineraries/${itineraryId}/remove/transfer/${rateKey}`,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async UpdateTransfers(itineraryId, hotelCode) {
        try {
            const response = await axiosInstance.put(
                `/itineraries/transfers/edit/${itineraryId}`,
                { hotelCode },
            );

            return response.data.newTransfers;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async addItineraryAttraction(itineraryId, attractionData) {
        try {
            const response = await axiosInstance.put(
                `/itineraries/${itineraryId}/add/attraction`,
                { attractionData },
            );

            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while adding the itinerary attraction:",
                error,
            );
            throw error;
        }
    }
}

export default ItineraryService;
