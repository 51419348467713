import { axiosInstance } from "src/config/axiosInstance";

class ProjectsService {
    static async updateProjectTerms(projectId, updateData) {
        try {
            const response = await axiosInstance.put(
                `/projects/updateTerms/${projectId}`,
                updateData,
            );
            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while updating the Itinerary:",
                error,
            );
            throw error;
        }
    }

    static async fetchProjectsList(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/company/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async deleteProjectById(projectId) {
        try {
            const response = await axiosInstance.post(
                `/projects/delete/${projectId}`,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
    static async deleteProjects(emailsIds) {
        try {
            const response = await axiosInstance.delete(
                `/projects/delete`,
                emailsIds,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
    static async archiveProjects(emailsIds) {
        try {
            const response = await axiosInstance.delete(
                `/projects/delete`,
                emailsIds,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
    static async fetchProjectDetails(projectId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/${projectId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchProjectDetailsByLast8Digits(
        last8Digits,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/notifications/${last8Digits}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createProject(data, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/projects/new`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                return response.data; // Assuming the project data is in the response's data property
            } catch (error) {
                console.error("Error creating project:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        // Return null or an appropriate value if the retries are exhausted
        return null;
    }

    static async fetchWorkUser(userId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/projects/user/${userId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchProjectsSummary(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/summary/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }

        // Después de los intentos, lanzar una excepción indicando el fallo
        throw new Error(
            `Failed to fetch project details after ${maxRetries} retries`,
        );
    }

    static async fetchProjectsSummaryVat(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/summary/parents/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }

        // Después de los intentos, lanzar una excepción indicando el fallo
        throw new Error(
            `Failed to fetch project details after ${maxRetries} retries`,
        );
    }

    static async fetchDataDashboard(companyId, data, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const url = `/projects/dashboard/${companyId}`;

                // Verificar si se proporcionan fechas y agregarlas a la URL si es así
                if (data) {
                    const response = await axiosInstance.post(
                        `/projects/dashboard/${companyId}`,
                        data,
                    );
                    return response;
                } else {
                    return await axiosInstance.get(url);
                }
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }

        // Si llegamos a este punto, lanzar una excepción para indicar el fallo
        throw new Error(
            `Failed to fetch project details after ${maxRetries} retries`,
        );
    }

    static async fetchProjectPreferences(projectId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/preferences/${projectId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async saveFeedback(data) {
        try {
            console.log("data", data);
            const response = await axiosInstance.post(`/feedback/new`, data);
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    static async saveProviderFeedback(data) {
        try {
            console.log("data", data);
            const response = await axiosInstance.post(
                `/feedback/provider/new`,
                data,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    static async saveItemFeedback(data) {
        try {
            const response = await axiosInstance.post(
                `/feedback/item/new`,
                data,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async checkOffersFlights(projectId){
        try{

            const response=await axiosInstance.put(`/projects/udapteFligthsOffers/${projectId}`);

            return response?.data;

        }catch(error){
            console.error("An error occurred:", error);
            throw error;
        }
    }
}

export default ProjectsService;
