import { useState } from "react";
import PropTypes from "prop-types";
import Iconify from "../iconify";

const Star = ({ filled, onClick, onMouseEnter, onMouseLeave, disabled }) => {
    const style = {
        display: "inline-block",
        marginRight: "5px",
        cursor: disabled ? "default" : "pointer",
        color: filled ? "#ffd700" : "#e4e5e9",
    };
    return (
        <span
            style={style}
            onClick={disabled ? null : onClick}
            onMouseEnter={disabled ? null : onMouseEnter}
            onMouseLeave={disabled ? null : onMouseLeave}
        >
            <Iconify width="30px" icon={filled ? "bi:star-fill" : "bi:star"} />
        </span>
    );
};

Star.propTypes = {
    filled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export const FormSecondStep = ({ ratingValue, setRatingValue }) => {
    const [hover, setHover] = useState(null);

    return (
        <div className="flex flex-col items-center">
            <h1 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-5">
                Select your hotel star rating
            </h1>
            <div>
                {Array(5)
                    .fill(0)
                    .map((_, index) => {
                        const disabled = index < 0;
                        const rating = index + 1;

                        return (
                            <Star
                                key={`star-${index + 1}`}
                                filled={rating <= (hover || ratingValue)}
                                onClick={() =>
                                    setRatingValue(rating < 3 ? 3 : rating)
                                }
                                onMouseEnter={() => setHover(rating)}
                                onMouseLeave={() => setHover(null)}
                                disabled={disabled}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

FormSecondStep.propTypes = {
    ratingValue: PropTypes.number.isRequired,
    setRatingValue: PropTypes.func.isRequired,
};
