import { useState } from "react";
import PropTypes from "prop-types";
import {
    Button,
    DialogActions,
    DialogContent,
    Typography,
} from "@mui/material";
import { checkBoxitem, hotelBoardTypes, hotelCategories } from "src/constants";
import { FormSecondStep } from "./FormSecondStep";
import { FormFirstStep } from "./FormFirtsStep";
import { FormThirdstep } from "./FormThirdstep";
import { DialogFormSubmitButton } from "./DialogFormSubmitButton";

export const DialogForm = ({
    questionnaireData,
    flightsOptions,
    destinations,
    handleCheckboxChange,
    setQuestionnaireData,
    setFlightsOptions,
    handleQuestionnaireSubmit,
    handleClose,
}) => {
    const [checkBoxItemState, setCheckBoxItemState] = useState(checkBoxitem);

    const handleChange = (value) => {
        handleCheckboxChange(value);
        setCheckBoxItemState((prevState) =>
            prevState.map((item) =>
                item.value === value
                    ? { ...item, checked: !item.checked }
                    : item,
            ),
        );
    };

    const [formStep, setFormStep] = useState(1);

    return (
        <DialogContent
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                gap: "1rem",
                padding: "1rem",
            }}
        >
            {formStep === 1 && (
                <>
                    <Typography
                        sx={{
                            marginTop: "5px",
                            textAlign: "center",
                            padding: "0 1rem",
                            
                        }}
                        variant="h4"
                    >
                        Please select the services you would like to include in
                        your booking package: 
                    </Typography>
                    <FormFirstStep
                        checkBoxitemState={checkBoxItemState}
                        questionnaireData={questionnaireData}
                        hotelCategories={hotelCategories}
                        hotelBoardTypes={hotelBoardTypes}
                        handleChange={handleChange}
                        setQuestionnaireData={setQuestionnaireData}
                    />
                </>
            )}

            {formStep === 2 && questionnaireData?.preferences?.hotel && (
                <>
                    <Typography sx={{ marginTop: "5px" }}>
                        <h1 className="text-lg sm:text-2xl">
                            Please choose your preferred hotel Category
                        </h1>
                    </Typography>

                    <FormSecondStep
                        ratingValue={questionnaireData.hotelCategory}
                        setRatingValue={(value) =>
                            setQuestionnaireData({
                                ...questionnaireData,
                                minHotelCategory: value,
                                hotelCategory: value,
                                maxHotelCategory: value,
                            })
                        }
                    />
                </>
            )}

            {formStep === 2 && !questionnaireData?.preferences?.hotel && (
                <>
                    <Typography sx={{ marginTop: "5px" }}>
                        <h1 className="text-lg sm:text-2xl">
                            You are all set!
                        </h1>
                    </Typography>
                </>
            )}

            {formStep === 3 && questionnaireData.preferences.flights && (
                <>
                    <Typography sx={{ marginTop: "5px" }}>
                        <h1 className="text-lg sm:text-2xl">
                            Please Enter the Flight Details
                        </h1>
                    </Typography>
                    <FormThirdstep
                        destinations={destinations}
                        flightsOptions={flightsOptions}
                        setFlightsOptions={setFlightsOptions}
                        setQuestionnaireData={setQuestionnaireData}
                        questionnaireData={questionnaireData}
                    />
                </>
            )}

            <DialogActions
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    // padding: 0,
                }}
                className="p-[0!important] sm:p-[24px!important]"
            >
                {formStep === 2 && questionnaireData.preferences.flights && (
                    <>
                        <Button
                            sx={{
                                width: "50%",
                            }}
                            variant="contained"
                            color="grey"
                            onClick={() => setFormStep(1)}
                        >
                            Go back
                        </Button>
                    </>
                )}
                <DialogFormSubmitButton
                    departureCity={flightsOptions.departureCity}
                    formStep={formStep}
                    setFormStep={setFormStep}
                    handleClose={handleClose}
                    needsForFlights={questionnaireData.preferences.flights}
                    needsForHotels={questionnaireData.preferences.hotel}
                    handleQuestionnaireSubmit={handleQuestionnaireSubmit}
                />
            </DialogActions>
        </DialogContent>
    );
};

DialogForm.propTypes = {
    questionnaireData: PropTypes.object,
    handleCheckboxChange: PropTypes.func,
    destinations: PropTypes.array,
    flightsOptions: PropTypes.object,
    setQuestionnaireData: PropTypes.func,
    handleQuestionnaireSubmit: PropTypes.func,
    handleClose: PropTypes.func,
    setFlightsOptions: PropTypes.func,
};

export default DialogForm;
