import CustomPopover, { usePopover } from "src/components/custom-popover";
import { IconButton, MenuItem } from "@mui/material";
import React, { useState } from "react";

import Iconify from "src/components/iconify";
import { m } from "framer-motion";

const currencyOptions = [
    { value: "EUR", label: "Euro", icon: "currency-eur" },
    { value: "USD", label: "US Dollar", icon: "currency-usd" },
];

const CurrencyPopover = ({ onChangeCurrency }) => {
    const [selectedCurrency, setSelectedCurrency] = useState(
        sessionStorage.getItem("currency") || "USD",
    );
    const popover = usePopover();

    const handleChangeCurrency = (currency) => {
        setSelectedCurrency(currency);
        sessionStorage.setItem("currency", currency);
        onChangeCurrency(currency);
        window.location.reload();
        popover.onClose();
    };

    return (
        <>
            <IconButton
                component={m.button}
                onClick={popover.onOpen}
                sx={{
                    width: 40,
                    height: 40,
                    ...(popover.open && {
                        bgcolor: "action.selected",
                    }),
                }}
            >
                <Iconify
                    icon={`currency-${selectedCurrency.toLowerCase()}`}
                    sx={{ borderRadius: 0.65, width: 28 }}
                />
                {selectedCurrency}
            </IconButton>

            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                sx={{ width: 160 }}
            >
                {currencyOptions.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === selectedCurrency}
                        onClick={() => handleChangeCurrency(option.value)}
                    >
                        <Iconify
                            icon={`currency-${option.value.toLowerCase()}`}
                            sx={{
                                borderRadius: 0.65,
                                width: 28,
                                color: "white",
                            }}
                        />
                        {option.label}
                    </MenuItem>
                ))}
            </CustomPopover>
        </>
    );
};

export default CurrencyPopover;
