/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ItineraryService } from "src/api/tz";
import axiosInstance from "src/config/axiosInstance";

const cleanString = (str) => {
    if (!str) return "";
    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .toLowerCase();
};

export const useAttractionAccordion = (
    attraction,
    itineraryData,
    day,
    setItineraryDailyData,
    setItineraryData,
) => {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [loading, setLoading] = useState(false);

    const { addItineraryAttraction } = ItineraryService;

    useEffect(() => {
        let index = findItineraryIndex(
            itineraryData,
            cleanString(attraction?.attractionCity),
        );
        index =
            index === -1
                ? findItineraryIndex(
                      itineraryData,
                      cleanString(attraction?.primaryDestinationName),
                  )
                : index;
        if (
            !itineraryData?.[index]?.products.some(
                (product) => product.title === attraction.title,
            )
        ) {
            setIsLiked(false);
            return;
        }
        const isLiked = itineraryData?.[index]?.products.some(
            (product) => product.title === attraction.title,
        );
        setIsLiked(isLiked);
    }, [itineraryData, attraction?.title]);

    const findItineraryIndex = (itineraryData, city) => {
        return itineraryData.findIndex(
            (itinerary) => cleanString(itinerary.city) === city,
        );
    };

    const getProductCode = (productDb) => {
        return productDb?.productCode;
    };

    const updateProduct = (product, attractionTitle, productDb) => {
        if (product?.title === attractionTitle) {
            return {
                ...product,
                productCode: getProductCode(productDb),
            };
        }
        return product;
    };

    const updateItinerary = (itinerary, attraction, productDb) => {
        const itineraryCity = cleanString(itinerary.city);
        if (
            itineraryCity === cleanString(attraction.attractionCity) ||
            itineraryCity === cleanString(attraction?.primaryDestinationName)
        ) {
            return {
                ...itinerary,
                products: itinerary.products.map((product) =>
                    updateProduct(product, attraction.title, productDb),
                ),
                timeline: itinerary?.timeline?.map((product) =>
                    updateProduct(product, attraction.title, productDb),
                ),
            };
        }
        return itinerary;
    };

    const handleAddAttraction = async () => {
        let index = findItineraryIndex(
            itineraryData,
            cleanString(attraction?.attractionCity),
        );
        index =
            index === -1
                ? findItineraryIndex(
                      itineraryData,
                      cleanString(attraction?.primaryDestinationName),
                  )
                : index;
        try {
            if (!isLiked) {
                attraction.slot = "morning";
                attraction.day = day;
                const newItineraryData = [...itineraryData];
                newItineraryData?.[index]?.products.push(attraction);
                setItineraryDailyData((prev) =>
                    prev.map((itinerary) =>
                        (cleanString(itinerary.city) ===
                            cleanString(attraction?.attractionCity) ||
                            cleanString(itinerary.city) ===
                                cleanString(
                                    attraction?.primaryDestinationName,
                                )) &&
                        itinerary.day === day
                            ? {
                                  ...itinerary,
                                  products: [...itinerary.products, attraction],
                                  timeline: [...itinerary.timeline, attraction],
                              }
                            : itinerary,
                    ),
                );

                setIsLiked(true);
                await addAttraction(attraction);
            } else {
                setIsLiked(false);
                !loading && handleDeleteAttraction(attraction);
                setItineraryDailyData((prev) =>
                    prev.map((itinerary) =>
                        cleanString(itinerary.city) ===
                            cleanString(attraction?.attractionCity) ||
                        cleanString(itinerary.city) ===
                            cleanString(attraction?.primaryDestinationName)
                            ? {
                                  ...itinerary,
                                  products: itinerary.products.filter(
                                      (product) =>
                                          product.title !== attraction.title,
                                  ),
                                  timeline: itinerary.timeline.filter(
                                      (product) =>
                                          product.title !== attraction.title,
                                  ),
                              }
                            : itinerary,
                    ),
                );
                setItineraryData((prev) =>
                    prev.map((itinerary) =>
                        itinerary.city === attraction.attractionCity
                            ? {
                                  ...itinerary,
                                  products: itinerary.products.filter(
                                      (product) =>
                                          product.title !== attraction.title,
                                  ),
                              }
                            : itinerary,
                    ),
                );
            }
        } catch (error) {
            console.log(
                "An error occurred while booking activity:",
                error?.message,
            );
        }
    };

    const addAttraction = async (attraction) => {
        const itinerary = itineraryData.find(
            (itinerary) =>
                cleanString(itinerary.city) ===
                    cleanString(attraction?.attractionCity) ||
                cleanString(itinerary.city) ===
                    cleanString(attraction?.primaryDestinationName),
        );
        try {
            const updatedData = {
                ...attraction,
                itineraryId: itinerary?._id,
                day: day,
                slot: attraction?.slot || "morning",
                city:
                    attraction?.primaryDestinationName ||
                    attraction?.attractionCity,
                createdFrom: "adams",
            };

            setLoading(true);
            const response = await addItineraryAttraction(
                itinerary?._id,
                updatedData,
            );
            setLoading(false);
            const productDb = response?.attraction;

            setItineraryDailyData((prev) => {
                const updatedItinerary = prev.map((itinerary) =>
                    updateItinerary(itinerary, attraction, productDb),
                );
                return updatedItinerary;
            });

            setItineraryData((prev) => {
                const updatedItinerary = prev.map((itinerary) =>
                    updateItinerary(itinerary, attraction, productDb),
                );
                return updatedItinerary;
            });
        } catch (error) {
            setLoading(false);
            console.log(
                "An error occurred while booking activity:",
                error?.message,
            );
        }
    };
    const handleDeleteAttraction = (attraction) => {
        try {
            let itineraryIndex = findItineraryIndex(
                itineraryData,
                attraction.attractionCity,
            );

            itineraryIndex =
                itineraryIndex === -1
                    ? findItineraryIndex(
                          itineraryData,
                          cleanString(attraction?.primaryDestinationName),
                      )
                    : itineraryIndex;

            const productCode = itineraryData[itineraryIndex].products.find(
                (product) => product.title === attraction.title,
            ).productCode;

            axiosInstance.delete(
                `${process.env.REACT_APP_HOST_API}/itineraries/${itineraryData[itineraryIndex]?._id}/remove/product/${productCode}`,
            );
        } catch (error) {
            console.log(
                "An error occurred while deleting activity:",
                error?.message,
            );
        }
    };

    return {
        isLiked,
        loading,
        isAccordionOpen,

        setIsAccordionOpen,
        handleAddAttraction,
    };
};
