import { HOST_API, HOST_API_FLIGHTS, TZ_API_KEY } from "src/config-global";

import axios from "axios";
import { storageKeys } from "src/utils";

//baseURL: HOST_API,
const axiosInstance = axios.create({    
  baseURL: HOST_API,
  headers: {
    "tz-api-key": TZ_API_KEY,
  }
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem(storageKeys.TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(
            (error.response && error.response.data) || "Something went wrong",
        );
    },
);

const flightAPI = axios.create({
    baseURL: HOST_API_FLIGHTS,
});

flightAPI.interceptors.response.use(
    (res) => res,
    (err) =>
        Promise.reject(
            (err.response && err.response.data) || "Something went wrong",
        ),
);

export { axiosInstance, flightAPI };

export default axiosInstance;
