import { axiosInstance } from "src/config/axiosInstance";

class CustomersService {
    static async createCustomer(customerData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    "/customers/new",
                    customerData,
                );
                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async fetchCustomersList(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/customers/company/${companyId}`,
                );
                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async deleteCustomerById(customerId) {
        try {
            const response = await axiosInstance.post(
                `/customers/delete/${customerId}`,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async fetchCustomerDetails(customerId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/customers/${customerId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching customer details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchProjectsByCustomer(customerId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/customer/${customerId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchCustomersSummary(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/customers/summary/${companyId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching customer details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchCustomerBookings(customerId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/bookings/customer/allbookings/${customerId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching bookings customer:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchCustomerQuotations(customerId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/customers/customer/quotations/${customerId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching customer quotations:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchCustomerByEmail(customerEmail, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/customers/email/${customerEmail}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching customer details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchCustomerPreferences(
        customerEmail,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/customers/preferences/update/${customerEmail}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching customer details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchCustomerPreferencesById(
        customerId,
        maxRetries = 3,
        retryDelay = 1000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/customers/customer/preferences/ById/${customerId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching customer details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchCustomerPreferencesByProjectId(
        projectId,
        maxRetries = 3,
        retryDelay = 1000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/customers/customer/preferences/ByProjectId/project/${projectId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching customer details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async updateCustomerPreferences(data) {
        try {
            console.log("updating preferences...", data);
            const response = await axiosInstance.post(
                `/customers/customer/preferences`,
                data,
            );
            console.log("response", response);
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    static async updateCustomerDetails(data, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.put(
                    `/customers/update/${data.email}`,
                    data,
                );
                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
}

export default CustomersService;
