import { axiosInstance } from "src/config/axiosInstance";

class DestinationsService {
    static async fetchDestinationsList(
        destinationUrlNames,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(`/cities`, {
                    destinationUrlNames,
                });
                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }
}

export default DestinationsService;
