function isMinutes(duration) {
    return typeof duration === "number";
}
export function parseHHMM(duration) {
    if (isMinutes(duration)) {
        let hours = Math.floor(duration / 60);
        let minutes = duration % 60;
        return `${hours} hours ${minutes > 0 ? minutes + " minutes" : ""}`;
    }

    if (typeof duration === "object") {
        let hours = Math.floor(
            duration?.fixedDurationInMinutes / 60 ||
                duration?.variableDurationFromMinutes / 60,
        );
        let minutes = duration?.fixedDurationInMinutes % 60;
        return `${hours} hours ${minutes > 0 ? minutes + " minutes" : ""}`;
    }
    const [hours, minutes] = duration?.split(":") || [];
    return `${hours} hours ${minutes > 0 ? minutes + " minutes" : ""}`;
}
