import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
} from "@mui/material";
import { AirportShuttle, ArrowBack, ArrowForward } from "@mui/icons-material";

const colors = ["success", "warning", "error", "info", "primary", "secondary"];

export const FormFirstStep = ({
    checkBoxitemState,
    questionnaireData,
    handleChange,
}) => {
    const [askForTransfers, setAskForTransfers] = useState(
        questionnaireData?.preferences?.flights &&
            questionnaireData?.preferences?.hotel,
    );

    useEffect(() => {
        setAskForTransfers(
            questionnaireData?.preferences?.flights &&
                questionnaireData?.preferences?.hotel,
        );
    
    },[questionnaireData?.preferences?.flights, questionnaireData?.preferences?.hotel])

    const handleAddTransfers = () => {
        setAskForTransfers(false);
        if(questionnaireData?.preferences?.transfers) return;
        handleChange("transfers");
    };

    const handleDeleteTransfers = () => {
        setAskForTransfers(false);
        if(!questionnaireData?.preferences?.transfers) return;
        handleChange("transfers");
    }

    return (
        <div className="grid sm:grid-cols-2 px-5">
            {checkBoxitemState.map((box, index) => {
                if (
                    box?.value === "transfers" &&
                    (!questionnaireData?.preferences?.flights ||
                        !questionnaireData?.preferences?.hotel)
                )
                    return null;

                return (
                    <FormControlLabel
                        key={box.id}
                        control={
                            <Checkbox
                                checked={
                                    questionnaireData.preferences[box.value]
                                }
                                onChange={() => handleChange(box.value)}
                                color={colors[index % colors.length]}
                            />
                        }
                        label={box.label}
                        sx={{
                            "& .MuiFormControlLabel-label": {
                                fontSize: "1.5rem",
                                fontWeight: "500",
                                color: colors[index % colors.length], // Apply the color to the label
                                lineHeight: "4rem", // Adjust the line height here
                                marginRight: 2,
                            },
                        }}
                    />
                );
            })}
            <Dialog 
                open={askForTransfers}
                onClose={() => setAskForTransfers(false)}
                fullWidth
            >
                <DialogTitle className="flex justify-center items-center gap-x-2">
                    <AirportShuttle />
                    Would you like to add transfers to your package?
                </DialogTitle>
                
                <DialogContent
                    sx={{
                        overflow: "hidden",
                    }}
                >
                    <div className="flex justify-center items-center gap-x-2 mb-6">
                        <div className="flex justify-center items-center bg-slate-50 shadow-md h-12 w-32 gap-x-2">
                            <h2>Airport</h2>
                        </div>
                        <div className="flex flex-col items-center">
                            <ArrowBack fontSize="small" />
                            <ArrowForward />
                        </div>
                        <div className="flex justify-center items-center bg-slate-50 shadow-md h-12 w-32">
                            <h2>Hotel</h2>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="contained" color="primary"
                        onClick={handleAddTransfers}
                    >
                        Yes
                    </Button>
                    <Button 
                        variant="contained" color="error"
                        onClick={handleDeleteTransfers}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

FormFirstStep.propTypes = {
    checkBoxitemState: PropTypes.array.isRequired,
    questionnaireData: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    hotelCategories: PropTypes.array.isRequired,
    setQuestionnaireData: PropTypes.func.isRequired,
    hotelBoardTypes: PropTypes.array.isRequired,
};
