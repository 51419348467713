import { useCallback, useRef, useState } from "react";
import { axiosInstance } from "src/config/axiosInstance";
import { endpoints } from "src/utils/axios";
import { LOCAL_STORAGE_CURRENCY } from 'src/constants';
import { ExchangeRateContext } from "./context";

const SPECIAL_TOKEN = process.env.REACT_APP_TZ_API_KEY;
const TIME_LAPSE = 600000; // 10 minutes

export function ExchangeRateProvider({ children }) {
    const timeRef = useRef(null);
    const [exchangeRate, setExchangeRate] = useState({
        currency: "EUR",
        rate: 1,
    });
    const updateExchangeRate = useCallback(async () => {
        try {
            const userCurrency = localStorage.getItem(LOCAL_STORAGE_CURRENCY) || "EUR";
            const now = Date.now();
            if (timeRef.current && now - timeRef.current < TIME_LAPSE) {
                // The function was executed less than 10 minutes ago
                return;
            }
            timeRef.current = now;
            const response = await axiosInstance.post(
                endpoints.exchangeRate,
                { currencyCode: userCurrency },
                { headers: { "tz-api-key": SPECIAL_TOKEN } },
            );
            setExchangeRate(response.data);
        } catch (error) {
            console.error("FETCH EXCHANGE ERROR", error);
        }
    }, []);

    // useEffect(() => {
    //     updateExchangeRate();
    // }, [updateExchangeRate]);

    return (
        <ExchangeRateContext.Provider
            value={{
                exchangeRate,
                setExchangeRate,
                updateExchangeRate,
            }}
        >
            {children}
        </ExchangeRateContext.Provider>
    );
}
