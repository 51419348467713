import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// layouts
import CompactLayout from "src/layouts/compact";
import MainLayout from "src/layouts/main";
import SimpleLayout from "src/layouts/simple";
// components
import { SplashScreen } from "src/components/loading-screen";

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import("src/pages/home"));
const Page500 = lazy(() => import("src/pages/500"));
const Page403 = lazy(() => import("src/pages/403"));
const Page404 = lazy(() => import("src/pages/404"));
const FaqsPage = lazy(() => import("src/pages/faqs"));
const AboutPage = lazy(() => import("src/pages/about-us"));
const ContactPage = lazy(() => import("src/pages/contact-us"));
const PricingPage = lazy(() => import("src/pages/pricing"));
const TimelinePage = lazy(() => import("src/pages/timeline"));
const PaymentPage = lazy(() => import("src/pages/payment"));
const ComingSoonPage = lazy(() => import("src/pages/coming-soon"));
const MaintenancePage = lazy(() => import("src/pages/maintenance"));
const AgentView = lazy(() => import("src/pages/agent-view"));
const AgentViewBook = lazy(() => import("src/pages/agent-view-book"));
const ClientView = lazy(() => import("src/pages/client-view"));
const ClientBookView = lazy(() => import("src/pages/client-book-view"));
const ClientHome = lazy(() => import("src/pages/client-home"));
const ClientPlan = lazy(() => import("src/sections/client-view/view/index.js"));
const AgencyPaymentPage = lazy(() => import("src/pages/agency-payment"));
const ProviderView = lazy(() => import("src/pages/provider-view"));
const TermsPage = lazy(() => import("src/pages/terms-conditions"));
const PrivacyPolicyPage = lazy(() => import("src/pages/privacy-policy"));
const CookiePolicyPage = lazy(() => import("src/pages/cookie-policy"));
const SupportPage = lazy(() => import("src/pages/support-us"));


// PRODUCT
const ProductListPage = lazy(() => import("src/pages/product/list"));
const ProductDetailsPage = lazy(() => import("src/pages/product/details"));
const ProductCheckoutPage = lazy(() => import("src/pages/product/checkout"));



// ----------------------------------------------------------------------

export const mainRoutes = [
    {
        element: (
            <MainLayout>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </MainLayout>
        ),
        children: [
            { path: "about-us", element: <AboutPage /> },
            { path: "agent-view", element: <AgentView /> },
            { path: "client-view", element: <ClientView /> },
            {
                path: "provider-view/:projectId/:companyId/:companyName/:title",
                element: <ProviderView />,
            },

            { path: "agent-client-view", element: <ClientView /> },
            { path: "client-book-view", element: <ClientBookView /> },
            { path: "ai-travel-planner", element: <ClientHome /> },
            { path: "ai-travel-planner/itinerary", element: <ClientPlan /> },
            { path: "contact-us", element: <ContactPage /> },
            { path: "faqs", element: <FaqsPage /> },
            {
                path: "product",
                children: [
                    { element: <ProductListPage />, index: true },
                    { path: "list", element: <ProductListPage /> },
                    { path: ":id", element: <ProductDetailsPage /> },
                    { path: "checkout", element: <ProductCheckoutPage /> },
                ],
            },
            { path: "terms-conditions", element: <TermsPage /> },
            { path: "privacy-policy", element: <PrivacyPolicyPage /> },
            { path: "cookie-policy", element: <CookiePolicyPage /> },
            { path: "support-us", element: <SupportPage /> },

        ],
    },
    {
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                path: "client-view-book/:id", // Add ":id" here to specify that it's an optional parameter
                element: <ClientBookView />,
            },
        ],
    },
    {
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                path: "agency-payment/:gdsprovider/:id",
                element: <AgencyPaymentPage />,
            },
        ],
    },
    {
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [{ path: "client-view/:id", element: <ClientView /> }],
    },
    {
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                path: "provider-view/:projectId/:companyId/:companyName/:title",
                element: <ProviderView />,
            },
        ],
    },

    {
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [
            {
                path: "agent-view-book/:id", // Add ":id" here to specify that it's an optional parameter
                element: <AgentViewBook />,
            },
        ],
    },
    {
        element: (
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        ),
        children: [{ path: "agent-view/:id", element: <AgentView /> }],
    },
    {
        element: (
            <SimpleLayout>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </SimpleLayout>
        ),
        children: [
            { path: "pricing", element: <PricingPage /> },
            { path: "timeline/:id", element: <TimelinePage /> },
            { path: "payment/:id", element: <PaymentPage /> },
        ],
    },
    {
        element: (
            <CompactLayout>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </CompactLayout>
        ),
        children: [
            { path: "coming-soon", element: <ComingSoonPage /> },
            { path: "maintenance", element: <MaintenancePage /> },
            { path: "500", element: <Page500 /> },
            { path: "404", element: <Page404 /> },
            { path: "403", element: <Page403 /> },
        ],
    },
];
