/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import * as yup from "yup";
import clsx from "clsx";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import CustomerPreferencesDialog from "src/components/questionnaires/customerPreferencesDialog";
import FormProvider from "src/components/hook-form/form-provider";
import RHFSearchAutocomplete from "src/components/hook-form/rhf-search-autocomplete";
import { useBannerForm } from "src/hooks";
import { SplashScreen } from "src/components/loading-screen";
import PopularDestinations from "./PopularDestinations";
import { BackIcon } from "src/sections/client-view/base/SVG";
import { useState, useEffect } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { getAirpotsList } from "src/utils";
import Iconify from "src/components/iconify";
import React from "react";

const schema = yup.object().shape({
    cities: yup.array().of(yup.string().required("City is required")),
    startDate: yup.array().of(yup.date().required("Start date is required")),
    endDate: yup.array().of(yup.date().required("End date is required")),
});

const BannerFormNew = ({ project, showGoButton, project_id }) => {
    const {
        cities,
        errors,
        endDate,
        methods,
        startDate,
        allCities,
        cityInputs,
        projectData,
        showQuestionnaire,
        splashScreenVisible,
        flightsOptions,
        isManualSelection,
        currentCityInput,

        onSubmit,
        handleSubmit,
        handleAddCityRow,
        setCurrentCityInput,
        handleCheckinChange,
        handleRemoveCityRow,
        handleCheckoutChange,
        handleQuestionnaireSubmit,
        handleCloseQuestionnaire,
        setFlightsOptions,
        handleCreateTour,
        resetSelection,
        setCustomerPreferences,
    } = useBannerForm(schema, project_id);

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        if (!currentCityInput) return;
        if (currentCityInput.length < 3) return;

        getAirpotsList(currentCityInput)
            .then((data) => {
                setLocations(
                    data.filter((location) => location.type === "city"),
                );
            })
            .catch((error) => {
                console.error(error);
            });
    }, [currentCityInput]);

    const shortcutsItems = [
        {
            label: "Next Week",
            getValue: () => {
                const today = dayjs();
                const nextWeek = today.add(7, "day");
                return [nextWeek.startOf("week"), nextWeek.endOf("week")];
            },
        },
        {
            label: "After 15 Days",
            getValue: () => {
                const today = dayjs();
                const nextWeek = today.add(14, "day");
                return [nextWeek.startOf("week"), nextWeek.endOf("week")];
            },
        },
        {
            label: "Next Month",
            getValue: () => {
                const today = dayjs();
                const startOfNextMonth = today.endOf("month").add(1, "day");
                return [startOfNextMonth, startOfNextMonth.endOf("month")];
            },
        },
        { label: "Reset", getValue: () => [null, null] },
    ];

    const handleDateRangeChange = (newRange, rowIndex) => {
        const updatedRanges = [...startDate];
        updatedRanges[rowIndex] = newRange;

        if (newRange[0]) {
            handleCheckinChange({
                target: {
                    name: `startDate[${rowIndex}]`,
                    value: dayjs(newRange[0]).format("YYYY-MM-DD"),
                },
            });
        }

        if (newRange[1]) {
            handleCheckoutChange({
                target: {
                    name: `endDate[${rowIndex}]`,
                    value: dayjs(newRange[1]).format("YYYY-MM-DD"),
                },
            });
        }
    };

    const getDateValue = (range, rowIndex) => {
        return startDate[rowIndex] && endDate[rowIndex]
            ? [dayjs(startDate[rowIndex]), dayjs(endDate[rowIndex])]
            : [null, null];
    };

    return (
        <>
            {splashScreenVisible && <SplashScreen />}
            <div className="animate__animated animate__fadeIn w-full">
                <CustomerPreferencesDialog
                    open={showQuestionnaire}
                    flightsOptions={flightsOptions}
                    customerEmail={projectData?.email}
                    projectId={projectData?._id}
                    destinations={allCities}
                    onSubmit={handleQuestionnaireSubmit}
                    onClose={handleCloseQuestionnaire}
                    setFlightsOptions={setFlightsOptions}
                    setCustomerPreferences={setCustomerPreferences}
                />

                <FormProvider
                    methods={methods}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {isManualSelection && (
                        <div className="w-full flex justify-start mt-2">
                            <button
                                type="button"
                                className="w-fit flex items-center gap-2 text-sm btn-red-gradient py-2 h-[auto!important] px-[1rem!important]"
                                onClick={resetSelection}
                            >
                                <BackIcon className="w-4 h-4" /> Go back
                            </button>
                        </div>
                    )}
                    {cities?.map((city, rowIndex) => (
                        <div
                            key={rowIndex + city}
                            className={clsx(
                                "flex lg:flex-row flex-col gap-x-10 mt-2 md:mt-4 overflow-hidden items-center justify-center",
                                project &&
                                    "md:gap-[24px] bg-white rounded-[20px] border border-black px-[16px] pt-[26px] md:pl-[24px] md:pr-[25px] md:pb-[28px] pb-[34px] w-full",
                            )}
                        >
                            <div
                                className={clsx(
                                    "block w-full transition-all ease duration-200",
                                    isManualSelection
                                        ? "lg:max-w-[220px]"
                                        : "md:max-w-full",
                                )}
                            >
                                <div className="flex items-center justify-between">
                                    <h4
                                        className={clsx(
                                            "font-medium text-black transition-all ease duration-200",
                                            isManualSelection
                                                ? "text-lg mb-[8px]"
                                                : "text-2xl md:text-3xl mb-[30px]",
                                        )}
                                    >
                                        {isManualSelection
                                            ? "Destination"
                                            : "Where do you want to go?"}
                                    </h4>
                                </div>

                                <RHFSearchAutocomplete
                                    name={`cities[${rowIndex}]`}
                                    label="Start Typing a City..."
                                    options={locations.map(
                                        (location) =>
                                            `${location.name}, ${location.country_name}`,
                                    )}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={
                                                isManualSelection
                                                    ? ""
                                                    : "Going to"
                                            }
                                            placeholder="Start Typing a City..."
                                            onChange={(e) =>
                                                setCurrentCityInput(
                                                    e?.target?.value,
                                                )
                                            }
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Iconify
                                                            icon="mdi:map-marker"
                                                            className="text-[#8E8E92]"
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            {isManualSelection && (
                                <div
                                    className={clsx(
                                        "gap-2 grid-cols-1 space-x-10 lg:grid-cols-3 transition-all ease duration-200",
                                        isManualSelection
                                            ? "w-full overflow-visible opacity-100 top-0 left-0 grid"
                                            : "w-0 overflow-hidden opacity-0 right-full hidden lg:grid",
                                    )}
                                >
                                    <div className="w-full">
                                        <h4 className="mt-2 mb-[8px] text-lg font-medium text-black">
                                            Date Range
                                        </h4>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DateRangePicker
                                                sx={{
                                                    minWidth: "20vw",
                                                }}
                                                format="DD/MM/YYYY"
                                                value={getDateValue(
                                                    [
                                                        startDate[rowIndex],
                                                        endDate[rowIndex],
                                                    ],
                                                    rowIndex,
                                                )}
                                                onChange={(newRange) =>
                                                    handleDateRangeChange(
                                                        newRange,
                                                        rowIndex,
                                                    )
                                                }
                                                minDate={dayjs()}
                                                renderInput={(
                                                    startProps,
                                                    endProps,
                                                ) => (
                                                    <React.Fragment>
                                                        <TextField
                                                            {...startProps}
                                                            label="Start Date"
                                                            fullWidth
                                                        />
                                                        <TextField
                                                            {...endProps}
                                                            label="End Date"
                                                            fullWidth
                                                        />
                                                    </React.Fragment>
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    {errors.startDate &&
                                        errors.startDate[rowIndex] && (
                                            <div className="text-xs text-red-600">
                                                {
                                                    errors.startDate[rowIndex]
                                                        .message
                                                }
                                            </div>
                                        )}
                                    {errors.endDate &&
                                        errors.endDate[rowIndex] && (
                                            <div className="text-xs text-red-600">
                                                {
                                                    errors.endDate[rowIndex]
                                                        .message
                                                }
                                            </div>
                                        )}

                                    {rowIndex === cities.length - 1 && (
                                        <div className="col-span-2 lg:ml-2 lg:col-span-1 h-auto flex justify-center lg:justify-start 2xl:justify-end items-center mt-10 gap-x-2 md:w-full  w-[100vw]">
                                            <div className="min-w-[40px] cursor-pointer">
                                                <button
                                                    className="border-[1px] border-slate-950 text-slate-950 px-3 py-2 lg:px-4 lg:py-3 2xl:px-5 rounded-lg cursor-pointer hover:bg-slate-950 hover:text-white transition-colors duration-300 ease-in-out font-semibold text-xs sm:text-sm lg:text-base 2xl:text-lg"
                                                    onClick={handleAddCityRow}
                                                >
                                                    Add City
                                                </button>
                                            </div>

                                            <div className="min-w-[40px] cursor-pointer">
                                                <button
                                                    className="border-[1px] border-slate-950 text-slate-950 px-3 py-2 lg:px-4 lg:py-3 2xl:px-5 rounded-lg cursor-pointer hover:bg-slate-950 hover:text-white transition-colors duration-300 ease-in-out font-semibold text-xs sm:text-sm lg:text-base 2xl:text-lg"
                                                    onClick={(event) => {
                                                        handleRemoveCityRow(
                                                            event,
                                                            `${rowIndex} + ${city}`,
                                                            `${rowIndex} + ${startDate[rowIndex]}`,
                                                            `${rowIndex} + ${endDate[rowIndex]}`,
                                                        );
                                                    }}
                                                >
                                                    Remove City
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-span-2 lg:col-span-1 min-w-[40px] cursor-pointer w-full">
                                        <h4 className="mb-[8px] font-medium text-white">
                                            ''
                                        </h4>
                                        <div className="flex lg:max-w-[60px] ">
                                            {cityInputs.length ===
                                                rowIndex + 1 && (
                                                <button
                                                    className="btn-red-gradient sm:w-auto w-[70vw]  whitespace-nowrap"
                                                    type="submit"
                                                >
                                                    Create Itinerary
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}

                    <div
                        className={clsx(
                            "w-full h-full transition-all ease duration-200",
                            isManualSelection
                                ? "max-w-0 max-h-0 overflow-hidden opacity-0"
                                : "max-w-full max-h-full opacity-100",
                        )}
                    >
                        <PopularDestinations
                            handleCreateTour={handleCreateTour}
                        />
                    </div>
                </FormProvider>
            </div>
        </>
    );
};

export default BannerFormNew;

BannerFormNew.propTypes = {
    project: PropTypes.object,
    showGoButton: PropTypes.bool,
    project_id: PropTypes.string,
    fetchItineraryData: PropTypes.func,
};
