import { axiosInstance } from "src/config/axiosInstance";

class ActivitiesService {
    static async postNewActivity(data) {
        try {
            const response = await axiosInstance.post(`/activities/new`, data);
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    ///activities/edit/:activityId updateActivity
    static async updateActivity(activityId, data) {
        try {
            const response = await axiosInstance.put(
                `/activities/edit/${activityId}`,
                data,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async bookActivity(bookingData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/viator/book`,
                    bookingData,
                    {
                        headers: { "Content-Type": "application/json" },
                    },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while booking activity:",
                    error?.message,
                );
                retries++;
                bookingData={
                    ...bookingData,
                    error:true
                }
                if(retries>=maxRetries){
                    return error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async bookActivityIntent(bookingData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    "/viator/bookIntent",
                    bookingData,
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while booking activity:",
                    error?.message,
                );
                retries++;
                if(retries>=maxRetries){
                    return error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async cancellActivity(bookingData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.delete(
                    `/viator/cancel-booking/${bookingData.bookingId}`,
                    {
                        headers: { "Content-Type": "application/json" },
                    },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while booking activity:",
                    error,
                );
                retries++;
                if(retries>=maxRetries){
                    return error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async ReHoldBooking(bookingId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.put(
                    `/viator/rebookhold/${bookingId}`,
                    {
                        headers: { "Content-Type": "application/json" },
                    },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while booking activity:",
                    error,
                );
                retries++;
                if(retries>=maxRetries){
                    return error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async FetchActivities(body,maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/viator/searchCityProducts`,
                    body
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching activities:",
                    error,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async FetchLightActivities(body,maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/viator/searchActivitiesLight`,
                    body
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching activities:",
                    error,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async FetchAttractions(body,maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/viator/attractions`,
                    body
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching attractions:",
                    error,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getProductDetails(data,maxRetries, retryDelay) {
        let retries = 0;
        const {productCode,currentLang} = data;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/productDetails/${productCode}/${currentLang}`,
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching attractions:",
                    error,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getSchedulesProduct(productCode,maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/viator/schedules/${productCode}`,
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching attractions:",
                    error,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getAvailability(body,maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/viator/availability`,
                    body
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching attractions:",
                    error,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

}

export default ActivitiesService;
