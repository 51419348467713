import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    Button,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    Autocomplete,
} from "@mui/material";
import {
    parsePhoneNumberFromString,
    isValidPhoneNumber,
} from "libphonenumber-js";
import { CustomersService } from "src/api/tz";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const phoneSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    terms: yup
        .boolean()
        .oneOf([true], "Please agree to the terms")
        .default(false),
    phoneNumber: yup
        .string()
        .min(10, "Phone number is too short")
        .required("Phone number is required"),
});

export const ContactModalForm = ({ setOpen, countryCode, setCountryCode }) => {
    const { createCustomer } = CustomersService;

    const [country, setCountry] = useState("");

    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
        getValues,
        setError,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(phoneSchema),
        defaultValues: {
            phoneNumber: "1",
        },
    });

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    const onSubmit = (data) => {
        localStorage.setItem("phone-number", data.phoneNumber);
        localStorage.setItem("name", data.name);
        createCustomer(
            {
                name: data.name,
                phone: `+${data.phoneNumber}`,
                country: country.label,
                status: "lead",
                customerType: "individual",
                company: "66747877d47803843cb9161b",
                user: "66747cdf4eb37c7bcfcb57a7",
                salesStage: "new",
                customerStage: "active",
            },
            3,
            1000,
        )
            .then(() => {
                handleClose();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const formatPhoneNumber = (value) => {
        const phoneNumber = parsePhoneNumberFromString(value, "US");
        return phoneNumber ? phoneNumber.formatNational() : value;
    };

    const handlePhoneNumberChange = (e) => {
        setValue("phoneNumber", e.target.value);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-end justify-center gap-2 p-4 w-full top-0 left-0"
        >
            <Box
                width={{ xs: "100%", md: "55%" }}
                bgcolor="background.paper"
                p={4}
                borderRadius={2}
                boxShadow={10}
                zIndex={10}
                position="relative"
                sx={{
                    backdropFilter: "blur(10px)",
                    boxShadow: "5px 0px 25px rgba(0, 0, 0, 0.5)",
                    "&::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderRadius: "inherit",
                        zIndex: -1,
                    },
                }}
            >
                <DialogTitle fontWeight="bold">
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        textAlign="center"
                        mb={2}
                    >
                        Enter your details
                    </Typography>
                    <Typography
                        variant="body2"
                        textAlign="center"
                        fontWeight="bold"
                        color="text.secondary"
                    >
                        We will send you a text to verify your phone number
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2} mt={2}>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                            )}
                        />

                        <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field }) => (
                                <PhoneInput
                                    {...register("phoneNumber", {
                                        required: true,
                                    })}                                    
                                    countryCodeEditable
                                    disableDropdown={false}
                                    country={country?.code?.toLowerCase() || "us"}
                                    value={field.value}
                                    onChange={(value) => {
                                        handlePhoneNumberChange(
                                            { target: { value } },
                                            field.onChange,
                                        );
                                    }}
                                    inputProps={{
                                        name: "phoneNumber",
                                        required: true,
                                    }}
                                    inputClass="p-6 rounded-lg border border-gray-300"
                                    containerClass="w-full"
                                />
                            )}
                        />
                        {errors.phoneNumber && (
                            <Typography variant="body2" color="error">
                                {errors.phoneNumber.message}
                            </Typography>
                        )}
                    </Box>
                    <Box mt={2}>
                        <Controller
                            name="terms"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            color="primary"
                                            value={field.value}
                                        />
                                    }
                                    label="I agree to the Terms & Conditions"
                                />
                            )}
                        />
                        {errors.terms && (
                            <Typography variant="body2" color="error">
                                {errors.terms.message}
                            </Typography>
                        )}
                    </Box>
                    <Box mt={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                        >
                            Submit
                        </Button>
                    </Box>

                    <Box mt={2}>
                        <Typography variant="body2" color="text.secondary">
                            By continuing, I agree to the{" "}
                            <Link to="/terms-conditions" target="_blank">
                                <Typography
                                    variant="body2"
                                    color="primary"
                                    component="span"
                                >
                                    Terms & Conditions
                                </Typography>
                            </Link>{" "}
                            &{" "}
                            <Link to="/privacy-policy" target="_blank">
                                <Typography
                                    variant="body2"
                                    color="primary"
                                    component="span"
                                >
                                    Privacy Policy
                                </Typography>
                            </Link>
                        </Typography>
                    </Box>
                </DialogContent>
            </Box>
        </form>
    );
};
