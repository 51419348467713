import { axiosInstance } from "src/config/axiosInstance";

class HotelsServices {
    static async fetchHotelOffers(searchCriteria, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/hotels/offers`,
                    searchCriteria,
                );
                return response.data;
            } catch (error) {
                console.error("Error fetching hotel offers:", error.message);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async fetchHotelDetails(
        GDSprovider,
        hotelId,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/hotels/hotelDetails/${GDSprovider}/${hotelId}`,
                );
                return response.data;
            } catch (error) {
                console.error("Error fetching hotel details:", error.message);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchHotelOffer(GDSprovider, hotelId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/hotels/offers/${GDSprovider}/${hotelId}`,
                );
                return response.data;
            } catch (error) {
                console.error(
                    "Error fetching hotel offer details:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createHotelBookingIntent(
        gdsprovider,
        bookingData,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/hotels/${gdsprovider}/booking/intent`,
                    bookingData,
                    { headers: { "Content-Type": "application/json" } },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while booking hotel:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async bookHotel(bookingData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/hotels/book`,
                    bookingData,
                    {
                        headers: { "Content-Type": "application/json" },
                    },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while booking hotel:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchHotelBooking(
        GDSprovider,
        bookingId,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/hotels/bookings/${GDSprovider}/${bookingId}`,
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching hotel booking:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchAllHotelBookings(maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(`/hotels/allBookings`);
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching hotel booking:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getHotelBookings(bookingData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(`/hotels/bookings`, {
                    gdsprovider: bookingData.gdsprovider,
                    body: {
                        from: 1,
                        to: 100,
                        start: bookingData.serviceObject.checkIn,
                        end: bookingData.serviceObject.checkIn,
                    },
                });
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching hotel booking:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async cancelHotel(bookingId, maxRetries, retryDelay) {
      let retries = 0;
      while (retries < maxRetries) {
          try {
              const response = await axiosInstance.delete(
                  `/hotels/cancelBooking/${bookingId}`
              );
              return response.data;
          } catch (error) {
              console.error(
                  "An error occurred while canceling hotel:",
                  error.message,
              );
              retries++;
              await new Promise((resolve) => setTimeout(resolve, retryDelay));
          }
      }
  }
}

export default HotelsServices;
