import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { LazyMotion, domAnimation, m, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import Favicon from '../favicon'; 
import { travelQuotes } from "../../constants";

export default function SplashScreen({ sx, ...other }) {
  const [quote, setQuote] = useState(travelQuotes[0]);
  const [showQuote, setShowQuote] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowQuote(false);
      setTimeout(() => {
        setQuote(travelQuotes[Math.floor(Math.random() * travelQuotes.length)]);
        setShowQuote(true);
      }, 500);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <LazyMotion features={domAnimation}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.default',
          zIndex: 9998,
          ...sx,
        }}
        {...other}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '80vw' }}>
          <Box sx={{ position: 'relative', mb: 16, width: 120, height: 120, marginRight: 10 }}>
            <m.div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              animate={{
                scale: [1, 0.9, 0.9, 1, 1],
                opacity: [1, 0.48, 0.48, 1, 1],
              }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                repeatDelay: 1,
                repeat: Infinity,
              }}
            >
              <Favicon disabledLink sx={{ width: '80%', height: '80%' }} />
            </m.div>

            <Box
              component={m.div}
              animate={{
                scale: [1.6, 1, 1, 1.6, 1.6],
                rotate: [270, 0, 0, 270, 270],
                opacity: [0.25, 1, 1, 1, 0.25],
                borderRadius: ["25%", "25%", "50%", "50%", "25%"],
              }}
              transition={{
                ease: "linear",
                duration: 3.2,
                repeat: Infinity,
              }}
              sx={{
                width: '100%',
                height: '100%',
                position: "absolute",
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                border: (theme) =>
                  `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
              }}
            />

            <Box
              component={m.div}
              animate={{
                scale: [1, 1.2, 1.2, 1, 1],
                rotate: [0, 270, 270, 0, 0],
                opacity: [1, 0.25, 0.25, 0.25, 1],
                borderRadius: ["25%", "25%", "50%", "50%", "25%"],
              }}
              transition={{
                ease: "linear",
                duration: 3.2,
                repeat: Infinity,
              }}
              sx={{
                width: '100%',
                height: '100%',
                position: "absolute",
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                border: (theme) =>
                  `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
              }}
            />
          </Box>

          <Box sx={{ height: 120, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <AnimatePresence mode="wait">
              {showQuote && (
                <m.div
                  key={quote}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                >
                  <Typography variant="h3" align="center" sx={{ px: 2 }}>
                    {quote}
                  </Typography>
                </m.div>
              )}
            </AnimatePresence>
          </Box>
        </Box>
      </Box>
    </LazyMotion>
  );
}

SplashScreen.propTypes = {
  sx: PropTypes.object,
};