import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { SplashScreen } from "src/components/loading-screen";
import SlidingMessage from "./SlidingMessage";
import { AuthContext } from "./auth-context";

export function AuthConsumer({ children }) {
    const [loading, setLoading] = useState(true);
    const user = sessionStorage.getItem("userName");
    const subscriptionPlan = sessionStorage.getItem("subscriptionPlan");

    // Check if the user is authenticated and has a valid subscription plan
    const isLogged = user != undefined ? true : false;
    const isValidSubscriptionPlan =
        subscriptionPlan === "premium" || subscriptionPlan === "starter";
    console.log(isLogged, user);

    return (
        <AuthContext.Consumer>
            {(auth) => {
                // Show the loading screen if the authentication context is still loading
                if (auth.loading) {
                    return <SplashScreen />;
                }

                // If the user is logged in but does not have a valid subscription plan, show the sliding message
                if (isLogged && !isValidSubscriptionPlan) {
                    return (
                        <>
                            {children}
                            <SlidingMessage />
                        </>
                    );
                }

                // Otherwise, render the children components normally
                return children;
            }}
        </AuthContext.Consumer>
    );
}

AuthConsumer.propTypes = {
    children: PropTypes.node,
};
