import PropTypes from "prop-types";
import { Button } from "@mui/material";

export const DialogFormSubmitButton = ({
    formStep,
    departureCity,
    needsForFlights,
    needsForHotels,

    setFormStep,
    handleClose,
    handleQuestionnaireSubmit,
}) => {
    const isStepOne = formStep === 1;
    const isStepTwo = formStep === 2;
    const isStepThree = formStep === 3;
    const needsFlights = needsForFlights;

    const goToNextStep = () => setFormStep(formStep + 1);
    const goToStep = (step) => setFormStep(step);
    const submitQuestionnaire = () => handleQuestionnaireSubmit();

    const getButtonLabel = () => {
        if (isStepOne && (needsForHotels || needsFlights)) {
            return "Next";
        } else if (isStepTwo && needsForFlights) {
            return "Next";
        } else if (isStepThree) {
            return "Let's Go";
        } else {
            return "Let's Go";
        }
    };

    const onSubmit = () => {
        if (isStepOne) {
            if (needsForFlights && !needsForHotels) {
                goToStep(3);
            } else {
                goToNextStep();
            }
        } else if (isStepTwo && needsForFlights) {
            goToNextStep();
        } else if (isStepThree) {
            submitQuestionnaire();
        } else {
            submitQuestionnaire();
        }
    };
    return (
        <div className="flex items-center gap-x-2 justify-end w-full">
            <Button variant="contained" onClick={handleClose}>
                Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={onSubmit}>
                {getButtonLabel()}
            </Button>
        </div>
    );
};

DialogFormSubmitButton.propTypes = {
    departureCity: PropTypes.string,
    formStep: PropTypes.number,
    needsForFlights: PropTypes.bool,
    needsForHotels: PropTypes.bool,
    setFormStep: PropTypes.func,
    handleClose: PropTypes.func,
    handleQuestionnaireSubmit: PropTypes.func,
};
