import React from "react";
import calendar from "./assets/calendar.svg";
import gear from "./assets/gear.svg";
import rating from "./assets/rating.svg";
import database from "./assets/database.svg";
import usersReview from "./assets/users-review.svg";
import booking from "./assets/booking.svg";
const Unparalleled = () => {
    const data = [
        {
            img: gear,
            title: "Personalized Planning",
            des: "Our AI-driven platform crafts unique travel plans tailored to your preferences, ensuring a unique experience that suits you. Share your plans with the community to receive valuable suggestions and ideas from fellow travelers.",
        },
        {
            img: calendar,
            title: "Real-time Availability",
            des: "Stay up-to-date with the latest information on hotels, attractions, and transportation. Our AI travel planning platform connects to real-time systems, allowing you to effortlessly plan and book every aspect of your unique AI vacation itinerary with confidence. ",
        },
        {
            img: rating,
            title: "User-friendly Interface",
            des: "Navigating our AI travel planner is a breeze, thanks to our intuitive design. We've created a seamless user experience that simplifies the planning process, allowing you to focus on creating unforgettable vacation's memories with AI trip planner.",
        },
        {
            img: database,
            title: "Comprehensive Destination Database",
            des: "With access to over 8 million destinations worldwide, you'll never run out of exciting places to explore. From hidden gems to popular tourist spots, our database covers it all, ensuring you find the perfect destination for your next adventure.",
        },
        { 
            img: usersReview, 
            title: "User-Generated Reviews and Opinions", 
            des: "Benefit from the wisdom of the crowd with over a billion traveler reviews and opinions at your fingertips. Our AI aggregates and analyzes this vast pool of data to provide you with insightful recommendations and honest feedback from fellow travelers.", 
        },
        {
            title:"Seamless Booking Integration",
            des: "Book flights, accommodations, and activities directly through our platform. Our AI streamlines the booking process, presenting you with the best options and deals, and ensuring a hassle-free experience from start to finish.", 
            img: booking,
        }
    ];
    return (
        <div
            id="about"
            className="mt-[50px] md:mt-[120px] scroll-m-[200px] px-2"
        >
            {/* title */}
            <div className="container mx-auto">
                <div className="md:flex justify-center mb-[40px] md:mb-[60px]">
                    <div className="max-w-[848px] w-full md:text-center">
                        <h2 className="font-bold text-base xs:text-2xl md:text-3xl text-main-black  mb-[24px] px-3 ">
                            Unmatched Features for Effortless Trip Planning with AI 
                        </h2>
                        <p className="text-normal text-[#3C3C43] p-4">
                            Discover a new era of travel planning with our
                            cutting-edge AI-powered platform. Designed to make
                            your vacation planning seamless and enjoyable, our
                            AI trip planning tool offers a host of unparalleled
                            features
                        </p>
                    </div>
                </div>
                <div className="lg:grid grid-cols-1 lg:grid-cols-3 gap-[24px] align-middle">
                    {data.map((single) => (
                        <div
                            key={single.title}
                            className="p-[24px] max-w-[500px] lg:max-w-[351px] bg-white border border-[#e5e5e500]"
                        >
                            <img
                                className="w-[42px] mb-[32px]"
                                src={single.img}
                                alt=""
                            />
                            <h4 className="mb-[24px] text-2xl text-main-black font-bold">
                                {single.title}
                            </h4>
                            <p className="text-normal text-light-black">
                                {single.des}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Unparalleled;
