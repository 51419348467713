import PropTypes from "prop-types";
import { t } from "i18next";

export const SearchDestinationInput = ({
    palceholder,
    className,

    destination = "",
    setDestinationSearch, // setDestinationSearch is a setState function setDestinationSearch: React.Dispatch<string>
    onFocus,
}) => {
    const handleChange = (e) => {
        setDestinationSearch(e.target.value.replace(" ", "-"));
    };
    return (
        <input
            type="text"
            className={className}
            placeholder={t(palceholder || "Search here", {
                defaultValue: "Search here",
            })}
            onChange={handleChange}
            value={destination}
            onFocus={onFocus && onFocus}
        />
    );
};

SearchDestinationInput.propTypes = {
    // General variables for the input field
    className: PropTypes.string,
    palceholder: PropTypes.string,
    onFocus: PropTypes.func,

    // Variables for the destination search
    destination: PropTypes.string.isRequired,
    setDestinationSearch: PropTypes.func.isRequired,
};
