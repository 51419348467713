export const AVAILABLE_LANGUAGES = ["en"];
export const AVAILABLE_CURRENCIES = [
    "EUR", // Euro
    "USD", // Dolar estadounidense
    "INR", // Rupia India
    //"CHF", // Franco Suizo
    //"JPY", // Yen Japonés
    //"GBP", // Libra Esterlina
    //"AUD", // Dolar Australiano
    "CAD", // Dolar Canadiense
    //"CNY", // Renminbi Chino
    //"SGD", // Dolar Singapurense
    //"NZD", // Dolar Neozelandes

    // "HNL", // Lempiras Hondureñas
    //"MXN", // Peso Mexicano
    "BRL", // Real Brasileño
    // "CLP", // Peso Chileno
    // "COP", // Peso Colombiano
    // "GTQ", // Queztal Guatemalteco
    // "CRC", // Colon Costarricense
];
export const LOCAL_STORAGE_LANGUAGE = "location-language";
export const LOCAL_STORAGE_CURRENCY = "location-currencyCode";
