// QuestionnaireDialog.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle } from "@mui/material";
import { CustomersService, ProjectsService } from "src/api/tz";
import { DialogForm } from "./DialogForm";

const CustomerPreferencesDialog = ({
    open,
    onClose,
    onSubmit,
    customerEmail,
    projectId,
    destinations,
    flightsOptions,
    setFlightsOptions,
    setCustomerPreferences,
}) => {
    const [maxRate, setMaxRate] = useState(0);
    const [questionnaireData, setQuestionnaireData] = useState({
        preferredActivities: "",
        dietaryRestrictions: "",
        hotelCategory: 1,
        minHotelCategory: null,
        maxHotelCategory: null,
        hotelLocation: null,
        hotelDistanceToCenter: "",
        maxRate: "",
        preferences: {
            hotel: false,
            activities: false,
            restaurants: false,
            transfers: false,
            carRental: false,
            flights: false,
            attractions: true,
            goodFood: false,
            artMuseums: false,
            spaRelax: false,
        },
    });

    const { updateCustomerPreferences } = CustomersService;

    const { fetchProjectPreferences } = ProjectsService;

    const hotelCategories = ["5-star", "4-star", "3-star", "Budget"];

    const hotelCategoryMapping = {
        "5-star": 5,
        "4-star": 4,
        "3-star": 3,
        Budget: 1, // You can adjust the values as needed
    };
    const hotelLocations = [
        { label: "City Center", value: { lat: 0, lng: 0 } },
        { label: "Suburb", value: { lat: 0, lng: 0 } },
        { label: "Countryside", value: { lat: 0, lng: 0 } },
        // Add more locations as needed
    ];

    const boardTypeCodes = {
        "Only Room": "RO",
        "Breakfast included": "BB",
        "Half Board": "HB",
        "Full Board": "FB",
    };

    const handleCheckboxChange = (preference) => {
        setQuestionnaireData((prevData) => ({
            ...prevData,
            preferences: {
                ...prevData.preferences,
                [preference]: !prevData.preferences[preference],
            },
        }));
    };

    const handleQuestionnaireSubmit = async () => {
        try {
            // Map user-friendly names to backend values before saving preferences
            const mappedPreferences = {
                ...questionnaireData,
            };

            // Map hotel board type to code
            mappedPreferences.hotelBoardTypeCode =
                boardTypeCodes[questionnaireData.hotelBoardType];

            mappedPreferences.maxRate = maxRate;
            mappedPreferences.projectId = projectId;
            mappedPreferences.hotelCategory =
                mappedPreferences.hotelCategory < 3
                    ? 3
                    : mappedPreferences.hotelCategory;

            const response = await updateCustomerPreferences(mappedPreferences);
            setCustomerPreferences(mappedPreferences);
            if (response) {
                onClose();
                onSubmit(response.data.updatedCustomerPreferences);
            } else {
                console.error("Failed to save preferences");
            }
        } catch (error) {
            console.error("An error occurred while saving preferences", error);
        }
    };

    useEffect(() => {
        const loadPreferences = async () => {
            try {
                // Load preferences from the backend
                if (!projectId) return;

                const response = await fetchProjectPreferences(
                    projectId,
                    3,
                    1000,
                );

                if (response?.data) {
                    const preferences = await response.data;

                    // Map backend values to user-friendly names when loading preferences
                    const mappedPreferences = {
                        ...preferences,
                        hotelCategory:
                            hotelCategories.find(
                                (category) =>
                                    hotelCategoryMapping[category] ===
                                    preferences.hotelCategory,
                            ) || null,
                        minHotelCategory:
                            hotelCategories.find(
                                (category) =>
                                    hotelCategoryMapping[category] ===
                                    preferences.minHotelCategory,
                            ) || null,
                        maxHotelCategory:
                            hotelCategories.find(
                                (category) =>
                                    hotelCategoryMapping[category] ===
                                    preferences.maxHotelCategory,
                            ) || null,
                    };

                    setQuestionnaireData((prevData) => ({
                        ...prevData,
                        ...mappedPreferences,
                    }));
                } else {
                    console.error("There are no preferences");
                }
            } catch (error) {
                console.error(
                    "An error occurred while loading preferences",
                    error,
                );
            }
        };

        loadPreferences();
    }, [projectId]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogForm
                questionnaireData={questionnaireData}
                flightsOptions={flightsOptions}
                destinations={destinations}
                handleCheckboxChange={handleCheckboxChange}
                setQuestionnaireData={setQuestionnaireData}
                setFlightsOptions={setFlightsOptions}
                handleQuestionnaireSubmit={handleQuestionnaireSubmit}
                handleClose={onClose}
            />
        </Dialog>
    );
};

export default CustomerPreferencesDialog;

CustomerPreferencesDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    customerEmail: PropTypes.string,
    projectId: PropTypes.string,
};
