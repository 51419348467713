import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { forwardRef } from "react";

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
    const storedUser = sessionStorage.getItem("user");
    let companylogo = "/assets/logotz.png";
    let logoWidth = 180;

    if (storedUser) {
        const { company: userCompany } = JSON.parse(storedUser);

        companylogo = userCompany?.avatarUrl
            ? userCompany?.avatarUrl
            : companylogo;
        logoWidth = userCompany?.logoWidth ? userCompany?.logoWidth : logoWidth;
    }

    // HDY AJUSTES EN TAMAÑO DEL LOGO DEL SIDEBAR
    const logo = (
        <Box
            ref={ref}
            component="img"
            src={companylogo}
            alt="Logo"
            sx={{
                width: parseInt(logoWidth),
                height: "auto",
                display: "inline-flex",
                cursor: "default",
                ...sx,
            }}
            {...other}
        />
    );

    return logo;
});

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default Logo;