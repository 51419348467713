import { useMemo, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation } from "swiper/modules";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Popover, CircularProgress, IconButton } from "@mui/material";
import { useLocales } from "src/locales";
import POPULAR_DESTINATIONS from "src/assets/data/popular-destinations.json";
import "swiper/css";
import "swiper/css/navigation";
import Iconify from "src/components/iconify";
import { format , add } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";

const validDestinations = POPULAR_DESTINATIONS.filter(
    (dest) =>
        !dest.ignore && !dest.destinations.some((item) => item.imgSrc === ""),
).splice(0, 7);

export default function PopularDestinations({ handleCreateTour }) {
    const [swiperSteps, setSwiperSteps] = useState({
        isBeginning: true,
        isEnd: false,
    });
    return (
        <section className="flex flex-col mt-4 gap-2">
            <p className="text-2xl md:text-3xl mb-3 mt-10">
                Popular Multi-City Vacation Packages
            </p>
            <div className="w-full">
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={20}
                    breakpoints={{
                        576: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: 4,
                        },
                    }}
                    onSlideChange={(swiper) =>
                        setSwiperSteps({
                            isBeginning: swiper.isBeginning,
                            isEnd: swiper.isEnd,
                        })
                    }
                >
                    {validDestinations.map((dest, index) => (
                        <SwiperSlide key={index}>
                            <DestinationSlideItem
                                destination={dest}
                                handleCreateTour={handleCreateTour}
                            />
                        </SwiperSlide>
                    ))}
                    <div
                        className={clsx(
                            "w-full justify-between gap-4 mt-2",
                            validDestinations.length <= 1 ? "hidden" : "flex",
                            validDestinations.length <= 3
                                ? "md:hidden"
                                : "md:flex",
                            validDestinations.length <= 4
                                ? "lg:hidden"
                                : "lg:flex",
                        )}
                    >
                        <SlideNextButton
                            isBeginning={swiperSteps.isBeginning}
                            isEnd={swiperSteps.isEnd}
                        />
                    </div>
                </Swiper>
            </div>
        </section>
    );
}

PopularDestinations.propTypes = {
    handleCreateTour: PropTypes.func,
};

function DestinationSlideItem({ destination, handleCreateTour }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [departureDate, setDepartureDate] = useState({
        date:add(new Date(),{months:3}),
        show:false
    });
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { t } = useLocales();

    const handleDecideStep=()=>{
        if(!departureDate.show || departureDate.date===null){
            return setDepartureDate((prev)=>({...prev,show:true}));
        }

        return handleSelectTour();
    }

    const handleSelectTour = async () => {
        setIsLoading(true);
        await handleCreateTour({...destination,checkin:format(departureDate.date,"yyyy-MM-dd")});
        handleClose();
        setIsLoading(false);
        setDepartureDate({date:add(new Date(),{months:3}),show:false});
    };

    const onChangeDepartureDate = (value) => {
        setDepartureDate((prev)=>({...prev,date:value}));
    }

    const handleGoBack=()=>{
        setDepartureDate((prev)=>({...prev,show:false}));
    }

    return (
        <div>
            <picture
                onClick={handleClick}
                className="relative flex overflow-hidden rounded-md w-full h-full aspect-square [&:hover>img]:scale-110 cursor-pointer"
            >
                <img
                    src={
                        destination.imgSrc || destination.destinations[0].imgSrc
                    }
                    alt={destination.description}
                    className="scale-100 transition-transform duration-200 ease-in w-full h-full object-cover"
                />
                <div className="absolute top-0 left-0 w-full h-full flex items-end bg-gradient-to-b from-transparent to-black/80 p-2">
                    <p className="w-full text-center text-white font-semibold text-2xl">
                        {destination.description}
                    </p>
                </div>
            </picture>

            <Popover
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                margin
            >
                <div
                    className={`flex flex-col p-2 w-[75vw] md:w-auto ${
                        !departureDate.show ? "gap-4" : ""
                    }`}
                >
                    {departureDate.show ? (
                        <DepartureDateInput
                            onChangeDeparture={onChangeDepartureDate}
                            value={departureDate.date}
                            handleGoBack={handleGoBack}
                            destinations={destination?.destinations}
                        />
                    ) : (
                        destination.destinations.map((dest, index) => (
                            <div
                                key={index}
                                className="flex gap-2 items-center"
                            >
                                <img
                                    src={dest.imgSrc}
                                    alt={dest.cityLabel}
                                    className="w-16 aspect-square object-cover rounded"
                                />
                                <p>
                                    {t("{{count}} days in {{city}}", {
                                        city: dest.cityLabel,
                                        count: dest.days,
                                    })}
                                </p>
                            </div>
                        ))
                    )}
                    <button
                        className="btn-red-gradient w-full md:w-auto whitespace-nowrap flex items-center justify-center gap-2 disabled:opacity-80"
                        type="button"
                        onClick={handleDecideStep}
                        disabled={isLoading}
                    >
                        {!isLoading
                            ? departureDate.show
                                ? "Create tour"
                                : "Select date"
                            : ""}
                        {isLoading && (
                            <CircularProgress color="inherit" size={18} />
                        )}
                    </button>
                </div>
            </Popover>
        </div>
    );
}

function SlideNextButton({ isBeginning, isEnd }) {
    const swiper = useSwiper();
    return (
        <>
            <button
                className="w-fit btn-red-gradient disabled:opacity-80 py-1 h-[auto!important] px-[1rem!important]"
                type="button"
                disabled={isBeginning}
                onClick={() => swiper?.slidePrev()}
            >
                Prev
            </button>
            <button
                className="w-fit btn-red-gradient disabled:opacity-80 py-1 h-[auto!important] px-[1rem!important]"
                type="button"
                disabled={isEnd}
                onClick={() => swiper?.slideNext()}
            >
                Next
            </button>
        </>
    );
}

const DepartureDateInput=({onChangeDeparture , value , handleGoBack , destinations })=>{

    const totalDays = useMemo(
        () => destinations.reduce((acc, curr) => acc + curr.days, 0),
        [destinations],
    );

    return (
        <div className="w-full h-full">
            <IconButton title="Go back" onClick={handleGoBack}>
                <Iconify icon="weui:back-filled" />
                <span className="text-sm">Back</span>
            </IconButton>
            <h4 className="px-[16px] font-medium text-black">Departure date</h4>
            <div>
                <DatePicker
                    onChange={onChangeDeparture}
                    format="yyyy-MM-dd"
                    minDate={new Date()}
                    value={value}
                    sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        border: "1px solid #DFDFDF",
                    }}
                />
                <p className="text-xs px-[16px] w-full text-right mb-1 text-gray-500 font-medium">
                    {totalDays} days in {destinations?.length} cities
                </p>
            </div>
        </div>
    );

}
