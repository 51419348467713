import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
    AVAILABLE_CURRENCIES,
    AVAILABLE_LANGUAGES,
    LOCAL_STORAGE_CURRENCY,
    LOCAL_STORAGE_LANGUAGE,
} from "src/constants";
import { TOLL_FREE_NUMBERS } from "src/constants/phone-number";

// API for IP location: https://docs.abstractapi.com/ip-geolocation

export function CurrencySelector() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [locationParams, setLocationParams] = useState({
        language: null,
        currency: null,
    });
    useEffect(() => {
        const getData = async () => {
            let language = window.localStorage.getItem(LOCAL_STORAGE_LANGUAGE);
            if (!language) {
                const langCode = navigator.language || navigator.userLanguage;
                const lang = langCode?.slice(0, 2);
                if (AVAILABLE_LANGUAGES.includes(langCode)) {
                    language = langCode;
                } else if (AVAILABLE_LANGUAGES.includes(lang)) {
                    language = lang;
                } else {
                    language = "en";
                }
                window.localStorage.setItem(LOCAL_STORAGE_LANGUAGE, language);
            }

            let currencyCode = window.localStorage.getItem(
                LOCAL_STORAGE_CURRENCY,
            );
            let countryCode = window.localStorage.getItem("country-code");
            let country = window.localStorage.getItem("country");

            if (!currencyCode || !country || !countryCode) {
                try {
                    const user_ip = await fetch(
                        "https://api.ipify.org/?format=json%27",
                    ).then((res) => res.text());
                    if (typeof user_ip !== "string") {
                        throw new Error("Invalid ip format");
                    }
                    // can receive ip_address as query parameter
                    const request = await fetch(
                        `https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.REACT_APP_IP_GEOLOCATION_API_KEY}&ip_address=${user_ip}`,
                    );
                    if (request.ok) {
                        const response = await request.json();
                        country = response.country;
                        countryCode = response.country_code;
                        const foundCurrency = response.currency?.currency_code;
                        if (AVAILABLE_CURRENCIES.includes(foundCurrency)) {
                            currencyCode = foundCurrency;
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
                if (!currencyCode) {
                    currencyCode = "EUR";
                }
                window.localStorage.setItem(
                    LOCAL_STORAGE_CURRENCY,
                    currencyCode,
                );
                window.localStorage.setItem("country", country);
                window.localStorage.setItem("country-code", countryCode);
                window.dispatchEvent(new Event("storage"));
            }
            setLocationParams({ language: language, currency: currencyCode });
        };
        getData();
    }, []);
    const handleChangeCurrency = (currency) => {
        window.localStorage.setItem(LOCAL_STORAGE_CURRENCY, currency);
        setLocationParams((prev) => ({ ...prev, currency: currency }));
        handleClose();
    };

    window.addEventListener("currencyChange", () => {
        const country = window.localStorage.getItem("country");
        setLocationParams({
            currency: TOLL_FREE_NUMBERS[country].currency,
            language: window.localStorage.getItem(LOCAL_STORAGE_LANGUAGE),
        });
        window.localStorage.setItem(
            "location-currencyCode",
            TOLL_FREE_NUMBERS[country].currency,
        );
    });

    if (!locationParams.language || !locationParams.currency) return null;
    return (
        <div>
            <Button
                aria-controls={open ? "currency-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="text"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                className="uppercase"
            >
                {locationParams.currency}
            </Button>
            <Menu
                id="currency-menu"
                MenuListProps={{
                    "aria-labelledby": "currency-menu-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                elevation={0}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {AVAILABLE_CURRENCIES.map((curr) => (
                    <MenuItem
                        key={curr}
                        onClick={() => handleChangeCurrency(curr)}
                        disableRipple
                    >
                        {curr}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
