import { axiosInstance } from "src/config/axiosInstance";

class TasksService {
    static async fetchTasksList(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/tasks/company/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async deleteTaskById(taskId) {
        try {
            const response = await axiosInstance.post(
                `/tasks/delete/${taskId}`,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async fetchTaskDetails(taskId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/tasks/${taskId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching task details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async updateTask(taskId, updateData) {
        try {
            const response = await axiosInstance.put(
                `/tasks/update/${taskId}`,
                updateData,
            );
            return response.data;
        } catch (error) {
            console.error("An error occurred while updating the task:", error);
            throw error;
        }
    }

    static async updateTaskStatus(taskId, status, name, userId, companyId) {
        try {
            const response = await axiosInstance.put(
                `/tasks/updateStatus/${taskId}/${status}/${name}/${userId}/${companyId}`,
            );
            return response.data;
        } catch (error) {
            console.error("An error occurred while updating the task:", error);
            throw error;
        }
    }

    static async fetchWorkSummary(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/tasks/summary/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching task details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
}

export default TasksService;
