export const TOLL_FREE_NUMBERS = {
    "United States": {
        number: "1-833-708-2422",
        address: "7901 4TH ST N STE 300, ST PETERSBURG, FL 33702",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3527.87462895546!2d-82.64075712609737!3d27.844391119436562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2e71105ab6f6d%3A0x574a8a9e0e177675!2s7901%204th%20St%20N%20suite%20300%2C%20St.%20Petersburg%2C%20FL%2033702%2C%20USA!5e0!3m2!1sen!2sin!4v1684769347934!5m2!1sen!2sin",
        src: "/assets/travel-planner/countries-flags/usa.png",
        currency: "USD",
    },

    Canada: {
        number: "1-855-910-5965",
        src: "/assets/travel-planner/countries-flags/canada.png",
        currency: "CAD",
    },
    Brazil: {
        number: "55-800-591-4924",
        src: "/assets/travel-planner/countries-flags/brazil.png",
        currency: "BRL",
    },
    Spain: {
        number: "34-900-861-614",
        address: "Carrera de San Jeronimo 15, Madrid 28014",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.64524656436!2d-3.7017093234464604!3d40.41670905563968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422881152f0ed9%3A0xbe3a3f7cf7a11c6a!2sCra%20de%20S.%20Jer%C3%B3nimo%2C%2015%2C%2028014%20Madrid%2C%20Spain!5e0!3m2!1sen!2sin!4v1692624116641!5m2!1sen!2sin",
        src: "/assets/travel-planner/countries-flags/spain.png",
        currency: "EUR",
    },
    India: {
        number: "1800-890-9456",
        address:
            "Unit No: 865B Tower B-1, 8th Floor, Spaze Itech Park, Gurgaon, Haryana 122018",
        map: "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1754.5940304554954!2d77.04189955822999!3d28.413581754726135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1649845030689!5m2!1sen!2sin",
        src: "/assets/travel-planner/countries-flags/india.png",
        currency: "INR",
    },
};
