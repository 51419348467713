//import Iconify from "src/components/iconify";
//import Label from "src/components/label";

import SvgColor from "src/components/svg-color";
import { paths } from "src/routes/paths";
import { useLocales } from "src/locales";
import { useMemo } from "react";

//import { useAuthContext } from "src/auth/hooks";

const icon = (name) => (
    <SvgColor
        src={`/assets/icons/navbar/${name}.svg`}
        sx={{ width: 1, height: 1 }}
    />
);

const ICONS = {
    aipilot: icon("ic_pilot"),
    job: icon("ic_job"),
    blog: icon("ic_blog"),
    chat: icon("ic_chat"),
    mail: icon("ic_mail"),
    activity: icon("ic_activity"),
    accounts: icon("ic_accounts"),
    campaign: icon("ic_campaign"),
    user: icon("ic_user"),
    file: icon("ic_file"),
    lock: icon("ic_lock"),
    tour: icon("ic_tour"),
    flight: icon("ic_flight"),
    transfer: icon("ic_transfer"),
    carRental: icon("ic_car_rental"),
    order: icon("ic_order"),
    label: icon("ic_label"),
    blank: icon("ic_blank"),
    kanban: icon("ic_kanban"),
    folder: icon("ic_folder"),
    banking: icon("ic_banking"),
    customer: icon("ic_customer"),
    lead: icon("ic_lead"),
    hotel: icon("ic_hotel"),
    booking: icon("ic_booking"),
    invoice: icon("ic_invoice"),
    quotation: icon("ic_quotation"),
    product: icon("ic_product"),
    calendar: icon("ic_calendar"),
    disabled: icon("ic_disabled"),
    external: icon("ic_external"),
    providers: icon("ic_providers"),
    menuItem: icon("ic_menu_item"),
    ecommerce: icon("ic_ecommerce"),
    analytics: icon("ic_analytics"),
    projects: icon("ic_project"),
    dashboard: icon("ic_dashboard"),
    incomes: icon("ic_incomes"),
    expenses: icon("ic_expenses"),
    ledger: icon("ic_ledger"),
    tasks: icon("ic_tasks"),
    promotions: icon("ic_promotions"),
};

export function useNavData() {
    const { t } = useLocales();
    /*  const { role } = useAuthContext(); */

    /*  const isAdmin = role === "admin"; */

    const data = useMemo(
        () => [
            //TODO: DO NOT DELETE THIS COMMENT.
            /* isAdmin && {
                subheader: t("admin"),
                items: [
                    {
                        title: t("All Bookings"),
                        path: paths.dashboard.admin.bookings,
                        icon: ICONS.booking,
                    },
                    {
                        title: t("Travel Agencies"),
                        path: paths.dashboard.admin.tzclients,
                        icon: ICONS.booking,
                    },
                    {
                        title: t("Agents"),
                        path: paths.dashboard.user.list,
                        icon: ICONS.booking,
                    },
                    {
                        title: t("Reports"),
                        path: paths.dashboard.admin.salesreport,
                        icon: ICONS.invoice,
                        children: [
                            {
                                title: t("Sales"),
                                path: paths.dashboard.admin.salesreport,
                            },
                            {
                                title: t("Businesses"),
                                path: paths.dashboard.admin.clientsreport,
                            },
                        ],
                    },
                ],
            }, */

            {
                subheader: t("Your Business"),
                items: [
                    {
                        title: t("AI Pilot"),
                        path: paths.dashboard.aipilot.acquisition,
                        icon: ICONS.aipilot,
                        children: [
                            {
                                title: t("acquisition"),
                                path: paths.dashboard.aipilot.acquisition,
                            },
                            {
                                title: t("conversion"),
                                path: paths.dashboard.aipilot.conversion,
                            },
                            {
                                title: t("experience"),
                                path: paths.dashboard.aipilot.experience,
                            },
                            {
                                title: t("engagement"),
                                path: paths.dashboard.aipilot.engagement,
                            },
                        ],
                    },
                    {
                        title: t("Analytics"),
                        path: paths.dashboard.root,
                        icon: ICONS.analytics,
                    },
                    {
                        title: t("Dashboard"),
                        path: paths.dashboard.general.booking,
                        icon: ICONS.dashboard,
                    },
                ],
            },

            // Product Sales
            // ----------------------------------------------------------------------
            {
                subheader: t("Projects & Travel Plans"),
                items: [
                    // // MAIL
                    {
                        title: t("Emails"),
                        path: paths.dashboard.mail,
                        icon: ICONS.mail,
                    },
                    // PROJECT
                    {
                        title: t("Projects"),
                        path: paths.dashboard.project.list,
                        icon: ICONS.projects,
                        // children: [
                        //   { title: t('list'), path: paths.dashboard.project.list },
                        // ],
                    },
                    //KANBAN TASKS
                    /*{
                        title: t("Tasks"),
                        path: paths.dashboard.kanban,
                        icon: ICONS.tasks,
                    },*/
                    // TOUR
                    {
                        title: t("Tours"),
                        path: paths.dashboard.tour.root,
                        icon: ICONS.tour,
                       /*  children: [
                            {
                                title: t("list"),
                                path: paths.dashboard.tour.root,
                            },
                            {
                                title: t("create"),
                                path: paths.dashboard.tour.new,
                            },
                        ], */
                    },

                    // ACTIVITY
                    {
                        title: t("Activities"),
                        path: paths.dashboard.activity.list,
                        icon: ICONS.activity,
                    },
                    // HOTEL
                    {
                        title: t("Hotels"),
                        path: paths.dashboard.hotel.list,
                        icon: ICONS.hotel,
                    },
                    // FLIGHTS
                    {
                        title: t("Flights"),
                        path: paths.dashboard.flight.home,
                        icon: ICONS.flight,
                    },
                    // TODO: Botón TRANSFER se ocula para cuando este la funcionalidad completa terminada
                    {
                        title: t("Transfers"),
                        path: paths.dashboard.transfer.home,
                        icon: ICONS.transfer,
                    },
                    // CAR RENTAL
                    /*  {
                        title: t("Car Rental"),
                        path: paths.dashboard.carRental.home,
                        icon: ICONS.carRental,
                    }, */
                ],
            },
            // CRM
            // ----------------------------------------------------------------------
            {
                subheader: t("Leads and Customers"),
                items: [
                    // LEADS
                    {
                        title: t("Leads"),
                        path: paths.dashboard.leads.list,
                        icon: ICONS.lead,
                    },
                    // GROUP LEADS
                    {
                        title: t("Group Leads"),
                        path: paths.dashboard.groupleads.list,
                        icon: ICONS.lead,
                    },
                    // PROMOTIONS
                    {
                        title: t("Promotions"),
                        path: paths.dashboard.promotion.root,
                        icon: ICONS.promotions,
                        children: [
                            {
                                title: t("list"),
                                path: paths.dashboard.promotion.root,
                            },
                            {
                                title: t("create"),
                                path: paths.dashboard.promotion.new,
                            },
                        ],
                    },
                    // CAMPAIGNS
                    {
                        title: t("Campaigns"),
                        path: paths.dashboard.campaigns.list,
                        icon: ICONS.campaign,
                    },

                    // CUSTOMERS
                    {
                        title: t("Customers"),
                        path: paths.dashboard.customer.list,
                        icon: ICONS.customer,
                    },
                    // PROVIDERS
                    {
                        title: t("Suppliers"),
                        path: paths.dashboard.providers.list,
                        icon: ICONS.providers,
                    },
                ],
            },

            // SALES MANAGEMENT
            // ----------------------------------------------------------------------
            {
                subheader: t("Sales Management"),
                items: [
                    // ORDER

                    // BOOKING
                    {
                        title: t("Bookings"),
                        path: paths.dashboard.booking.root,
                        icon: ICONS.booking,
                    },

                    // INVOICE
                    {
                        title: t("Invoices"),
                        path: paths.dashboard.invoice.root,
                        icon: ICONS.invoice,
                        children: [
                            {
                                title: t("list"),
                                path: paths.dashboard.invoice.root,
                            },
                            {
                                title: t("create"),
                                path: paths.dashboard.invoice.new,
                            },
                        ],
                    },

                    // QUOTATION
                    {
                        title: t("Quotation"),
                        path: paths.dashboard.quotation.root,
                        icon: ICONS.quotation,
                        children: [
                            {
                                title: t("list"),
                                path: paths.dashboard.quotation.root,
                            },
                            {
                                title: t("create"),
                                path: paths.dashboard.quotation.new,
                            },
                        ],
                    },

                    // BLOG
                    /*  {
             title: t('blog'),
             path: paths.dashboard.post.root,
             icon: ICONS.blog,
             children: [
               { title: t('list'), path: paths.dashboard.post.root },
               { title: t('details'), path: paths.dashboard.post.demo.details },
               { title: t('create'), path: paths.dashboard.post.new },
               { title: t('edit'), path: paths.dashboard.post.demo.edit },
             ],
           },

       
          // FILE MANAGER
           {
             title: t("file_manager"),
             path: paths.dashboard.fileManager,
             icon: ICONS.folder,
           },

          // CHAT
          {
            title: t("chat"),
            path: paths.dashboard.chat,
            icon: ICONS.chat,
          },

          // CALENDAR
          {
            title: t("calendar"),
            path: paths.dashboard.calendar,
            icon: ICONS.calendar,
          },*/

                    // KANBAN
                    // {
                    //   title: t('kanban'),
                    //   path: paths.dashboard.kanban,
                    //   icon: ICONS.kanban,
                    // },
                ],
            },

            // CRM
            // ----------------------------------------------------------------------
            {
                subheader: t("Income & Expenses"),
                items: [
                    // Income
                    {
                        title: t("Income"),
                        path: paths.dashboard.income?.list,
                        icon: ICONS.incomes,
                    },
                    // Expenses
                    {
                        title: t("Expenses"),
                        path: paths.dashboard.expense?.list,
                        icon: ICONS.expenses,
                    },
                    //ledger
                    {
                        title: t("Ledger"),
                        path: paths.dashboard.ledger.list,
                        icon: ICONS.ledger,
                    },
                    //chart of accounts
                    {
                        title: t("Chart of Accounts"),
                        path: paths.dashboard.chAccount.list,
                        icon: ICONS.accounts,
                    },

                    // Book Keeping
                    // {
                    //   title: t('bookkeeping'),
                    //    path: paths.dashboard.bookkeeping.list,
                    //   icon: ICONS.folder,
                    // },
                ],
            },
        ],
        [t],
    );

    return data;
}
