import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import { RouterLink } from "src/routes/components";
import { forwardRef } from "react";

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
        <Box
            ref={ref}
            component="img"
            src={"/assets/logotz.png"}
            alt="Logo"
            sx={{
                width: parseInt(180),
                height: parseInt(38),
                display: "inline-flex",
                cursor: "pointer",
                ...sx,
            }}
            {...other}
        />
    );

    if (disabledLink) {
        return logo;
    }

    return (
        <Link component={RouterLink} href="/" sx={{ display: "contents" }}>
            {logo}
        </Link>
    );
});

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default Logo;
