import React, { useEffect, useState, useRef } from "react";
import { CustomersService } from "src/api/tz";
import { useAuth0 } from "@auth0/auth0-react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const RecentItineraries = () => {
    const { user } = useAuth0();
    const [projects, setProjects] = useState([]);
    const discoverRef = useRef(null);

    useEffect(() => {
        const loadCustomerPreferences = async () => {
            if (!user?.email) return;
            try {
                const { data: preferences } = await CustomersService.fetchCustomerByEmail(
                    user.email,
                    1,
                    10000,
                );
                if (preferences.length > 0) {
                    const customerId = preferences[0]._id;
                    const { data: projects } = await CustomersService.fetchProjectsByCustomer(
                        user?.email, 
                        1,
                        3,
                    );
                    setProjects(projects);
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }
        };
        loadCustomerPreferences();
    }, [user?.email]);

    useEffect(() => {
        if (discoverRef.current && projects.length > 0) {
            discoverRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [projects]);

    return (
        <>
            {projects.length > 0 && (
                <div
                    ref={discoverRef}
                    id="discover"
                    className="container mt-[30px] md:mt-[50px] scroll-m-[200px] mx-auto"
                >
                    <div className="flex justify-between p-5">
                        <div className="max-w-[872px] mb-[30px]">
                            <h2 className="heading-1 mb-[24px] text-3xl">
                                Your Recent Itineraries
                            </h2>
                            <p className="max-w-[848px]">
                                These are the multi-city vacation packages that
                                you created recently
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {projects
                            .slice()
                            .reverse()
                            .filter((project) => project.phase !== "needs")
                            .map((project) => (
                                <div
                                    key={project._id}
                                    className="p-4 border rounded-lg"
                                >
                                    <img
                                        src={
                                            project.images[0] ||
                                            "https://images.pexels.com/photos/670261/pexels-photo-670261.jpeg?auto=compress&cs=tinysrgb&h=350"
                                        }
                                        alt={project.title}
                                        className="w-full h-auto mb-4 max-h-80 object-cover rounded-lg"
                                    />
                                    <h3 className="text-2xl font-bold">
                                        {project.title}
                                    </h3>

                                    <h3 className="text-lg">
                                        Begin date{" "}
                                        {`${getFormattedDate(
                                            project.startDate[0]
                                        )}`}
                                    </h3>
                                    <h6 className="text-xl font-bold text-uppercase">
                                        Status:{" "}
                                        {project.phase === "booking" 
                                            ? `Booked${project.totallyPaid ? '' : ' - Payment pending'}` 
                                            : `${project.phase}${project.totallyPaid ? '' : ' - Payment pending'}`}
                                    </h6>

                                    <div className="flex items-center justify-end">
                                        <a
                                            href={`/client-view/${project._id}?type=customer`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-main-red pt-2"
                                        >
                                            View Details
                                            <OpenInNewIcon className="ml-2" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </>
    );
};

function getFormattedDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export default RecentItineraries;
