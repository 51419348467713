import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
    Autocomplete,
    Checkbox,
    FormControlLabel,
    MenuItem,
    TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { PasssangersModal } from "./PasssangersModal";
import { getAirpotsList } from "src/utils";
import { useDebouncedCallback } from "src/hooks/use-debounced-callback";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import "dayjs/locale/en";

const classes = ["Economy", "Premium Economy", "Business", "First"];

export const FormThirdstep = ({
    flightsOptions,
    questionnaireData,
    setFlightsOptions,
    setQuestionnaireData,
}) => {
    const [locations, setLocations] = useState([]);
    const minDate = useMemo(
        () => new Date(flightsOptions.departureDate - 0 - 72 * 60 * 60 * 1000),
        [],
    );
    dayjs.locale('en'); 
    useEffect(() => {
        if (!flightsOptions.departureCity.name) return;
        if (flightsOptions.departureCity.name.length < 3) return;

        getAirpotsList(flightsOptions.departureCity.name)
            .then((data) => {
                setLocations(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [flightsOptions.departureCity.name]);

    const handleChangeDepartureCity = useDebouncedCallback((event, value) => {
        setQuestionnaireData({
            ...questionnaireData,
            preferences: {
                ...questionnaireData.preferences,
                flights: true,
            },
        });

        const location = locations.find((location) => location.name === value);

        setFlightsOptions({
            ...flightsOptions,
            departureCity: {
                name: value,
                code: location?.code,
            },
        });
    }, 300);

    const handleDepartureDateChange = (date) => {
        const newDate = new Date(date);
        setFlightsOptions({
            ...flightsOptions,
            departureDate: newDate,
        });
    };

    const handleClassChange = (event, newValue) => {
        setFlightsOptions({
            ...flightsOptions,
            class: newValue,
        });
    };
    return (
        <div className="flex flex-col ">
            <h1
                style={{
                    fontSize: "1.5rem",
                    fontWeight: "500",
                    lineHeight: "2rem",
                    marginBottom: "1rem",
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                }}
            >
                Please enter the city you will be departing from
            </h1>
            <div className="flex flex-col gap-y-3 w-full">
                <Autocomplete
                    className="w-full"
                    placeholder="Departure city"
                    label="Cities"
                    options={locations.map((location) => location.name)}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderOption={(props, option) => {
                        return (
                            <MenuItem {...props} key={option} value={option}>
                                {option}
                            </MenuItem>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Departure city"
                            onChange={(e) =>
                                handleChangeDepartureCity(e, e.target.value)
                            }
                        />
                    )}
                    onChange={handleChangeDepartureCity}
                />
                <div className="flex items-center gap-1">
                    <LocalizationProvider 
                        dateAdapter={AdapterDayjs}

                    >
                        <DatePicker
                            className="w-3/4"
                            label="Departure date"
                            value={dayjs(flightsOptions.departureDate)}
                            onChange={handleDepartureDateChange}
                            minDate={dayjs(minDate)}
                        />
                    </LocalizationProvider>

                    <PassangerInput
                        flightsOptions={flightsOptions}
                        setFlightsOptions={setFlightsOptions}
                    />
                </div>
                <Autocomplete
                    label="Class"
                    className="w-full"
                    select
                    value={flightsOptions.class}
                    options={classes}
                    renderInput={(params) => (
                        <TextField {...params} label="Class" />
                    )}
                    renderOption={(props, value) => {
                        return (
                            <MenuItem {...props} key={value} value={props}>
                                {value}
                            </MenuItem>
                        );
                    }}
                    onChange={(e, newValue) => handleClassChange(e, newValue)}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!!flightsOptions.directFlight}
                            onChange={() =>
                                setFlightsOptions((prev) => ({
                                    ...prev,
                                    directFlight: !prev.directFlight,
                                }))
                            }
                            name="directFlights"
                            color="primary"
                        />
                    }
                    label="Directs flights only"
                />
            </div>
        </div>
    );
};

function PassangerInput({ flightsOptions, setFlightsOptions }) {
    const [isPassangersModalOpen, setIsPassangersModalOpen] = useState(false);
    return (
        <div className="w-full">
            <div
                className="text-gray-500 border-[1px] py-4 px-2 rounded-lg cursor-pointer"
                style={{ fontSize: "0.75rem" }}
                onClick={() => setIsPassangersModalOpen(true)}
            >
                {`${flightsOptions.passangers.adults} adult(s) - ${flightsOptions.passangers.child} child(ren) - ${flightsOptions.passangers.infants} infant(s)`}
            </div>
            <PasssangersModal
                isPassangersModalOpen={isPassangersModalOpen}
                setIsPassangersModalOpen={setIsPassangersModalOpen}
                flightsOptions={flightsOptions}
                setFlightsOptions={setFlightsOptions}
            />
        </div>
    );
}

FormThirdstep.propTypes = {
    destinations: PropTypes.array.isRequired,
    flightsOptions: PropTypes.object.isRequired,
    questionnaireData: PropTypes.object.isRequired,
    setFlightsOptions: PropTypes.func.isRequired,
    setQuestionnaireData: PropTypes.func.isRequired,
};

PassangerInput.propTypes = {
    flightsOptions: PropTypes.object.isRequired,
    setFlightsOptions: PropTypes.func.isRequired,
};
