export const matchClassValues = (value) => {
    switch (value) {
        case "Economy":
            return "economy";
        case "Premium Economy":
            return "premium_economy";
        case "Business":
            return "business";
        case "First":
            return "first";
        default:
            return "";
    }
};
