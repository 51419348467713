import { axiosInstance } from "src/config/axiosInstance";

class FlightsService {
    static async fetchFlightOffers(searchCriteria, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/offers`,
                    searchCriteria,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                return response.data; // Assuming the flights data is in the response's data property
            } catch (error) {
                console.error("Error creating flight:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async bookFlight(bookingData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `flights/create`,
                    bookingData,
                    {
                        headers: { "Content-Type": "application/json" },
                    },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while booking flight:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async deleteFlightById(flightId) {
        try {
            const response = await axiosInstance.post(
                `/flights/delete/${flightId}`,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async fetchFlightDetails(flightId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/flights/${flightId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching flight details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchFlightDetailsForProject(
        projectId,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/flights/project/${projectId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching flight details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createFlight(data, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/flights/new`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                return response.data; // Assuming the project data is in the response's data property
            } catch (error) {
                console.error("Error creating flight:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
}

export default FlightsService;
