import React from "react";
import BannerTable from "../Components/BannerTable/BannerTable";

const HomeBanner = () => {
    return (
        <div className="lg:pt-[10px]">
            <div className="container px-0 mx-auto">
                <div className="w-full  banner-wrap lg:rounded-[20px] ">
                    <div className=" pt-[54px] md:pt-[120px] relative pb-[40px] md:pb-[60px]">
                        <div className="px-[20px] lg:px-[194px]">
                            <h1 className="heading text-center   ">
                                Meet Mr. Adam, <br></br>Your Personal Travel
                                Assistant
                            </h1>
                            <h3 className="mt-[24px] heading-2 text-center text-white  ">
                                Mr. Adam crafts the ultimate package deals for
                                you
                            </h3>
                        </div>
                    </div>
                    <BannerTable />
                </div>
            </div>
        </div>
    );
};

export default HomeBanner;
