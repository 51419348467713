import {
    LocalActivity,
    MonetizationOnTwoTone as Coins,
    CardGiftcard,
} from "@mui/icons-material";
import { Dialog, Typography, Box, styled } from "@mui/material";
import { ContactModalForm } from "./ContactModalForm";
import { useEffect, useState } from "react";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        maxWidth: "900px",
        width: "100%",
        margin: theme.spacing(2),
        padding: 0,
        overflow: "visible",
    },
}));

const getUserLocationData = async () => {
    const user_ip = await fetch("https://api.ipify.org/?format=json%27").then(
        (res) => res.text(),
    );
    if (typeof user_ip !== "string") {
        throw new Error("Invalid ip format");
    }
    const request = await fetch(
        `https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.REACT_APP_IP_GEOLOCATION_API_KEY}&ip_address=${user_ip}`,
    );
    const data = await request.json();
    return data;
};

export const ContactModal = () => {
    const [open, setOpen] = useState(true);
    const [countryCode, setCountryCode] = useState(
        window.localStorage.getItem("country-code") || "",
    );

    useEffect(() => {
        if (localStorage.getItem("phone-number")) {
            setOpen(false);
        }
    }, []);

    useEffect(() => {
        if (countryCode === "null") {
            getUserLocationData().then((data) => {
                window.localStorage.setItem("country-code", data.country_code);
                window.localStorage.setItem("country", data.country);
                window.dispatchEvent(new Event("country-code"));
            });
        }
    }, []);

    return (
        <>
            <StyledDialog
                sx={{
                    "& .MuiDialog-paper": {
                        backgroundColor: "transparent",
                        maxWidth: "800px",
                        width: "100%",
                        margin: 2,
                        padding: 0,
                        overflow: "visible",
                    },
                }}
                open={open}
            >
                <Box
                    display="flex"
                    flexDirection={{ xs: "column", md: "row-reverse" }}
                    position="relative"
                    height={{ xs: "100vh", md: "40vh" }}
                >
                    <ContactModalForm
                        setOpen={setOpen}
                        countryCode={countryCode == "null" ? "US" : countryCode}
                        setCountryCode={setCountryCode}
                    />
                    <Box
                        sx={{
                            background:
                                "linear-gradient(197.24deg, rgb(194, 212, 255) 16.45%, rgb(251, 241, 255) 39.26%, rgb(234, 243, 243) 59.99%)",
                        }}
                        width={{ xs: "100%", md: "55%" }}
                        p={4}
                        borderRadius={2}
                        boxShadow={3}
                        position={{ xs: "static", md: "absolute" }}
                        left={{ md: "-8%" }}
                        top={{ md: "5%" }}
                        zIndex={0}
                    >
                        <Typography variant="h6" fontWeight="bold" mb={2}>
                            UNLOCK THESE BENEFITS
                        </Typography>
                        <Box display="flex" flexDirection="column" gap={2}>
                            <Box display="flex" alignItems="center">
                                <Coins
                                    color="warning"
                                    sx={{ fontSize: 40, mr: 2 }}
                                />
                                <Box>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        Earn points
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        ₹100 on Sign Up & Cashback on Bookings
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <LocalActivity
                                    color="primary"
                                    sx={{ fontSize: 40, mr: 2 }}
                                />
                                <Box>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        Loyalty Benefits
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Extra Discounts, Free Seat Selection
                                        etc.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <CardGiftcard
                                    color="secondary"
                                    sx={{ fontSize: 40, mr: 2 }}
                                />
                                <Box>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        Exclusive Offers
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Daily Steal Deals & Special Discounts
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            mt={3}
                            p={2}
                            borderRadius={1}
                            sx={{
                                background: "rgba(255, 255, 255, 0.5)",
                                backdropFilter: "blur(10px)",
                            }}
                            boxShadow={2}
                        >
                            <Typography
                                variant="subtitle1"
                                color="primary.dark"
                                fontWeight="bold"
                            >
                                FLAT 5% OFF*
                            </Typography>
                            <Typography variant="body2" color="primary.dark">
                                on your first booking
                            </Typography>
                            <Typography
                                variant="body2"
                                color="primary.dark"
                                fontWeight="bold"
                                mt={1}
                            >
                                Use Coupon: ADAM
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </StyledDialog>
        </>
    );
};
