export const htmlToPlainText = (html="") => {
    return html
        .replace(/<br\s*\/?>/gi, "\n")
        .replace(/<p><\/p>/gi, "\n")
        .replace(/<p>/gi, "")
        .replace(/<\/p>/gi, "\n")
        .replace(/<em>/gi, "")
        .replace(/<\/em>/gi, "")
        .replace(/<a[^>]*>/gi, "")  
        .replace(/<\/a>/gi, "");    
};
