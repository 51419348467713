import { useCallback, useEffect, useState } from "react";

import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Iconify from "src/components/iconify";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import NotificationItem from "./notification-item";
import { NotificationsService } from "src/api/tz";
import Scrollbar from "src/components/scrollbar";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { m } from "framer-motion";
import { useBoolean } from "src/hooks/use-boolean";
import { useResponsive } from "src/hooks/use-responsive";
import { varHover } from "src/components/animate";

const TABS = [
    {
        value: "all",
        label: "All",
    },
    {
        value: "unread",
        label: "Unread",
    },
    {
        value: "archived",
        label: "Archived",
    },
];

export default function NotificationsPopover() {
    const [notificationsList, setNotificationsList] = useState([]);
    const drawer = useBoolean();

    useEffect(() => {
        async function fetchNotifications() {
            const companyId = sessionStorage.getItem("companyId");
            const notifications =
                await NotificationsService.fetchNotificationsList(
                    companyId,
                    3,
                    1000,
                );
            setNotificationsList(notifications.data);
        }
        if (drawer.value){ 
            fetchNotifications();
        }
    }, [drawer.value]);
    

    const smUp = useResponsive("up", "sm");

    const [currentTab, setCurrentTab] = useState("all");

    const handleChangeTab = useCallback((event, newValue) => {
        setCurrentTab(newValue);
    }, []);

    const computeTabCount = (tabValue) => {
        if (!notificationsList) {
            return 0;
        }

        if (tabValue === "all") {
            return notificationsList.length;
        } else if (tabValue === "unread") {
            return notificationsList.filter(
                (notification) => notification.isUnRead,
            ).length;
        } else if (tabValue === "archived") {
            return notificationsList.filter(
                (notification) => !notification.isUnRead,
            ).length;
        }
        return 0;
    };

    const [notifications, setNotifications] = useState(notificationsList);

    const totalUnRead = notifications
        ? notifications.filter((item) => item.isUnRead === true).length
        : 0;

    const handleMarkAllAsRead = () => {
        setNotifications(
            notifications.map((notification) => ({
                ...notification,
                isUnRead: false,
            })),
        );
    };

    const renderHead = (
        <Stack
            direction="row"
            alignItems="center"
            sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}
        >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                Notifications
            </Typography>

            {!!totalUnRead && (
                <Tooltip title="Mark all as read">
                    <IconButton color="primary" onClick={handleMarkAllAsRead}>
                        <Iconify icon="eva:done-all-fill" />
                    </IconButton>
                </Tooltip>
            )}

            {!smUp && (
                <IconButton onClick={drawer.onFalse}>
                    <Iconify icon="mingcute:close-line" />
                </IconButton>
            )}
        </Stack>
    );

    const renderTabs = (
        <>
            <Tabs value={currentTab} onChange={handleChangeTab}>
                {TABS.map((tab) => (
                    <Tab
                        key={tab.value}
                        value={tab.value}
                        label={
                            <Badge
                                badgeContent={computeTabCount(tab.value)}
                                color="error"
                                variant="dot"
                            >
                                {tab.label}
                            </Badge>
                        }
                    />
                ))}
            </Tabs>
        </>
    );

    const renderList = (
        <Scrollbar>
            <List disablePadding>
                {notificationsList && notificationsList.length > 0 ? (
                    notificationsList
                        .filter((notification) => {
                            if (currentTab === "all") return true;
                            if (currentTab === "unread")
                                return notification.isUnRead;
                            if (currentTab === "archived")
                                return !notification.isUnRead;
                            return true;
                        })
                        .map((notification) => (
                            <NotificationItem
                                key={notification._id}
                                notification={notification}
                            />
                        ))
                ) : (
                    <ListItem>
                        <ListItemText primary="No notifications available" />
                    </ListItem>
                )}
            </List>
        </Scrollbar>
    );

    return (
        <>
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                color={drawer.value ? "primary" : "default"}
                onClick={drawer.onTrue}
            >
                {/* HDY CAMBIO ESTILO DE CAMPANITA */}
                <Badge badgeContent={totalUnRead} color="error">
                    <Iconify
                        icon="solar:bell-bing-bold"
                        width={24}
                        style={{ color: "#FAFAFA" }}
                    />
                </Badge>
            </IconButton>

            <Drawer
                open={drawer.value}
                onClose={drawer.onFalse}
                anchor="right"
                slotProps={{
                    backdrop: { invisible: true },
                }}
                PaperProps={{
                    sx: { width: 1, maxWidth: 420 },
                }}
            >
                {renderHead}

                <Divider />

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ pl: 2.5, pr: 1 }}
                >
                    {renderTabs}
                    <IconButton onClick={handleMarkAllAsRead}>
                        <Iconify icon="solar:settings-bold-duotone" />
                    </IconButton>
                </Stack>

                <Divider />

                {renderList}
            </Drawer>
        </>
    );
}
