import React from "react";
import HomeTopBar from "../Components/HomeTopBar/HomeTopBar";
import HomeBanner from "./../HomeBanner/HomeBanner";
import Unparalleled from "./../Unparalleled/Unparalleled";
import Discover from "./../Discover/Discover";
import Help from "./../Help/Help";
import Footer from "../Components/Footer/Footer";
import "./../App.css";
import "./../index.css";
import { useAuth0 } from "@auth0/auth0-react";
import RecentItineraries from "../recent-itineraries/recent-itineraries";

const ClientHome = () => {
    const { user } = useAuth0();
    return (
        <div className="mx-auto">
            {" "}
            {/* Apply text-center class here */}
            <HomeTopBar />
            <HomeBanner />
            {user?.email && <RecentItineraries />}
            <Unparalleled />
            <Discover />
            {/* <Help /> */}
            <Footer />
        </div>
    );
};

export default ClientHome;
