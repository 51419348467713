import { useMemo, useState } from "react";
import { TOLL_FREE_NUMBERS } from "../../constants/phone-number";
import { MenuItem, Select, Skeleton } from "@mui/material";

export const TollFreeNumber = () => {
    const [country, setCountry] = useState(localStorage.getItem("country"));
    const loading = useMemo(() => {
        return !country;
    }, [country]);

    window.addEventListener("storage", () => {
        setCountry(localStorage.getItem("country"));
    });

    const isInTollFreeNumbers = useMemo(() => {
        return Object.keys(TOLL_FREE_NUMBERS).includes(country);
    }, [country]);

    const tollFreeNumber = useMemo(() => {
        return (
            TOLL_FREE_NUMBERS[country]?.number ||
            TOLL_FREE_NUMBERS["United States"].number
        );
    }, [country, TOLL_FREE_NUMBERS]);

    const handleCountryChange = (e) => {
        localStorage.setItem("country", e.target.value);
        setCountry(e.target.value);
        window.dispatchEvent(new Event("currencyChange"));
    };

    return (
        <div className="hidden lg:flex relative gap-2 items-center px-2 py-5">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Select
                        name="country"
                        label="Country"
                        value={isInTollFreeNumbers ? country : "United States"}
                        options={Object.keys(TOLL_FREE_NUMBERS)}
                        onChange={handleCountryChange}
                        sx={{
                            width: "200px",
                            height: "60px",
                            borderRadius: "10px",
                            alignContent: "center",
                        }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                        }}
                        SelectDisplayProps={{
                            style: {
                                display: "flex",
                                alignItems: "center",
                                alignContent: "center",
                                gap: "12px",
                                fontSize: "16px",
                            },
                        }}
                    >
                        {Object.keys(TOLL_FREE_NUMBERS).map((country) => (
                            <MenuItem
                                key={country}
                                value={country}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                    gap: "12px",
                                }}
                            >
                                <img
                                    src={TOLL_FREE_NUMBERS[country].src}
                                    alt="Phone icon"
                                    className="phone-icon"
                                    style={{ width: "40px" }}
                                />
                                <span>{country}</span>
                            </MenuItem>
                        ))}
                    </Select>

                    <a className="phone-text" href={`tel:${tollFreeNumber}`}>
                        {tollFreeNumber}
                    </a>
                </>
            )}
        </div>
    );
};

const Loader = () => (
    <>
        <Skeleton
            variant="rectangular"
            width={40}
            height={40}
            sx={{ borderRadius: "20px" }}
        />
        <Skeleton variant="text" width={100} height={40} />
    </>
);
