import PropTypes from "prop-types";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from "@mui/material";

export const PasssangersModal = ({
    isPassangersModalOpen,
    flightsOptions,
    setIsPassangersModalOpen,
    setFlightsOptions,
}) => {
    const handleAddAdults = () => {
        setFlightsOptions({
            ...flightsOptions,
            passangers: {
                ...flightsOptions.passangers,
                adults: flightsOptions.passangers.adults + 1,
            },
        });
    };
    const handleRemoveAdults = () => {
        setFlightsOptions({
            ...flightsOptions,
            passangers: {
                ...flightsOptions.passangers,
                adults: flightsOptions.passangers.adults - 1,
            },
        });
    };
    const handleAddChild = () => {
        setFlightsOptions({
            ...flightsOptions,
            passangers: {
                ...flightsOptions.passangers,
                child: flightsOptions.passangers.child + 1,
            },
        });
    };
    const handleRemoveChild = () => {
        setFlightsOptions({
            ...flightsOptions,
            passangers: {
                ...flightsOptions.passangers,
                child: flightsOptions.passangers.child - 1,
            },
        });
    };
    const handleAddInfants = () => {
        setFlightsOptions({
            ...flightsOptions,
            passangers: {
                ...flightsOptions.passangers,
                infants: flightsOptions.passangers.infants + 1,
            },
        });
    };
    const handleRemoveInfants = () => {
        setFlightsOptions({
            ...flightsOptions,
            passangers: {
                ...flightsOptions.passangers,
                infants: flightsOptions.passangers.infants - 1,
            },
        });
    };

    return (
        <Dialog
            open={isPassangersModalOpen}
            onClose={() => setIsPassangersModalOpen(false)}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "1rem",
                }}
            >
                <h1>Passangers</h1>
                <Button
                    onClick={() => setIsPassangersModalOpen(false)}
                    variant="outlined"
                >
                    Close
                </Button>
            </DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-5 p-5">
                    <div className="flex items-center justify-between border-[1px] rounded-lg p-2">
                        <Button
                            onClick={handleRemoveAdults}
                            disabled={flightsOptions.passangers.adults === 1}
                        >
                            -
                        </Button>
                        <div className="flex flex-col justify-center items-center w-48">
                            <div className="text-lg">Adults</div>
                            <div className="text-gray-500">
                                {flightsOptions.passangers.adults}
                            </div>
                        </div>
                        <Button onClick={handleAddAdults}>+</Button>
                    </div>
                    <div className="flex items-center justify-between border-[1px] rounded-lg p-2">
                        <Button
                            onClick={handleRemoveChild}
                            disabled={flightsOptions.passangers.child === 0}
                        >
                            -
                        </Button>
                        <div className="flex flex-col justify-center items-center w-48">
                            <div className="text-lg">Child</div>
                            <div className="text-gray-500">
                                {flightsOptions.passangers.child}
                            </div>
                        </div>
                        <Button onClick={handleAddChild}>+</Button>
                    </div>
                    <div className="flex items-center justify-between border-[1px] rounded-lg p-2">
                        <Button
                            onClick={handleRemoveInfants}
                            disabled={flightsOptions.passangers.infants === 0}
                        >
                            -
                        </Button>
                        <div className="flex flex-col justify-center items-center w-48">
                            <div className="text-lg">Infants</div>
                            <div className="text-gray-500">
                                {flightsOptions.passangers.infants}
                            </div>
                        </div>
                        <Button
                            onClick={handleAddInfants}
                            disabled={flightsOptions.passangers.infants === 5}
                        >
                            +
                        </Button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    padding: "1rem",
                }}
            >
                <Button
                    sx={{
                        width: "100%",
                    }}
                    variant="contained"
                    onClick={() => {
                        setIsPassangersModalOpen(false);
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

PasssangersModal.propTypes = {
    isPassangersModalOpen: PropTypes.bool,
    flightsOptions: PropTypes.shape({
        departureCity: PropTypes.string,
        departureDate: PropTypes.string,
        passangers: PropTypes.shape({
            adults: PropTypes.number,
            child: PropTypes.number,
            infants: PropTypes.number,
        }),
        class: PropTypes.string,
    }),
    setFlightsOptions: PropTypes.func,
    setIsPassangersModalOpen: PropTypes.func,
};
