import { useState } from "react";

export const useRoomsDialog = (initialLocations, currentRate) => {
    const [locations, setLocations] = useState(initialLocations);
    const [capacityExceeded, setCapacityExceeded] = useState(false);

    const addRoom = (city, newRoom) => {
        const newRooms = locations.map((location) => {
            if (location.city === city) {
                return {
                    ...location,
                    rooms: [...location.rooms, newRoom],
                };
            }
            return location;
        });
        setLocations(newRooms);
    };
    const handleAddRoom = (city) => {
        const newRoom = { adults: 2, children: 0, paxes: 2, city: city };
        addRoom(newRoom.city, newRoom);
    };

    const removeRoom = (city, roomIndex) => {
        if (locations.length === 1 && locations[0].rooms.length === 1) return;
        const newRooms = locations.map((location) => {
            if (location.city === city) {
                return {
                    ...location,
                    rooms: location.rooms.filter(
                        (room, index) => index !== roomIndex,
                    ),
                };
            }
            return location;
        });
        setLocations(newRooms);
    };

    const handleAddAdults = (city, roomIndex) => {
        locations.forEach((location) => {
            if (location.city === city) {
                if (
                    location.rooms[roomIndex].adults >= currentRate.adults ||
                    location.rooms[roomIndex].childrens >=
                        currentRate?.childrens
                ) {
                    setCapacityExceeded(true);
                    return;
                } else {
                    setLocations(
                        locations.map((location) =>
                            location.city === city
                                ? {
                                      ...location,
                                      rooms: location.rooms.map(
                                          (room, index) =>
                                              index === roomIndex
                                                  ? {
                                                        ...room,
                                                        adults: room.adults + 1,
                                                    }
                                                  : room,
                                      ),
                                  }
                                : location,
                        ),
                    );
                }
            }
        });
    };

    const removeAdultFromRoom = (city, roomIndex) => {
        setLocations(
            locations.map((location) =>
                location.city === city
                    ? {
                          ...location,
                          rooms: location.rooms.map((room, index) =>
                              index === roomIndex && room.adults > 0
                                  ? { ...room, adults: room.adults - 1 }
                                  : room,
                          ),
                      }
                    : location,
            ),
        );
    };

    const handleAddChildren = (city, roomIndex) => {
        setLocations(
            locations.map((location) =>
                location.city === city
                    ? {
                          ...location,
                          rooms: location.rooms.map((room, index) =>
                              index === roomIndex
                                  ? { ...room, children: room.children + 1 }
                                  : room,
                          ),
                      }
                    : location,
            ),
        );
    };

    const removeChildrenFromRoom = (city, roomIndex) => {
        setLocations(
            locations.map((location) =>
                location.city === city
                    ? {
                          ...location,
                          rooms: location.rooms.map((room, index) =>
                              index === roomIndex && room.children > 0
                                  ? { ...room, children: room.children - 1 }
                                  : room,
                          ),
                      }
                    : location,
            ),
        );
    };

    const handleAddPaxes = (city, roomIndex) => {
        setLocations(
            locations.map((location) =>
                location.city === city
                    ? {
                          ...location,
                          rooms: location.rooms.map((room, index) =>
                              index === roomIndex
                                  ? { ...room, paxes: room.paxes + 1 }
                                  : room,
                          ),
                      }
                    : location,
            ),
        );
    };

    const removePaxesFromRoom = (city, roomIndex) => {
        setLocations(
            locations.map((location) =>
                location.city === city
                    ? {
                          ...location,
                          rooms: location.rooms.map((room, index) =>
                              index === roomIndex && room.paxes > 0
                                  ? { ...room, paxes: room.paxes - 1 }
                                  : room,
                          ),
                      }
                    : location,
            ),
        );
    };

    return {
        locations,
        capacityExceeded,

        setCapacityExceeded,
        setLocations,
        handleAddRoom,
        handleAddAdults,
        removeAdultFromRoom,
        handleAddChildren,
        removeChildrenFromRoom,
        handleAddPaxes,
        removePaxesFromRoom,
        removeRoom,
    };
};
