export const checkBoxitem = [
    {
        id: 1,
        label: "Hotels",
        checked: false,
        value: "hotel",
        visible: true, 
    },
    {
        id: 2,
        label: "Flights",
        checked: false,
        value: "flights",
        visible: true, 
    },
    {
        id: 3,
        label: "Things to do",
        checked: false,
        value: "attractions",
        visible: true, 
    },
    {
        id:4,
        label:"Transfers",
        checked:false,
        value:"transfers", 
        visible: false, 
    }
    /* {
        id: 4,
        label: "Car Rental",
        checked: false,
        value: "carRental",
    }, */
];
