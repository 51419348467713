import { useEffect, useState } from "react";
import axiosInstance from "src/config/axiosInstance";
import { useRouter } from "src/routes/hooks";
import { paths } from "src/routes/paths";

export const useSearchHotels = () => {
    const [loading, setLoading] = useState({
        search: false,
        save: false,
    });
    const [searchResults, setSearchResults] = useState([]);
    const [hotelSelected, setHotelSelected] = useState({});
    const [{ hotelName, hotelCity }, setHotelForm] = useState({
        hotelName: "",
        hotelCity: "",
    });
    const router = useRouter();

    console.log(...hotelName, hotelCity);

    useEffect(() => {
        if (hotelSelected?.name) {
            handleSaveHotel(hotelSelected);
        }
    }, [hotelSelected]);

    const handleSearchInfo = async () => {
        if (hotelName.trim() === "" || hotelCity.trim() === "") {
            return;
        }
        setLoading({
            ...loading,
            search: true,
        });
        try {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_HOST_API}/hotels/search`,
                {
                    name: hotelName,
                    place: hotelCity,
                },
            );

            setSearchResults(response.data.hotels);
            setLoading({
                ...loading,
                search: false,
            });
        } catch (error) {
            console.error("Error searching for hotels:", error);
        }
    };

    const handleSaveHotel = async (hotel) => {
        setLoading({
            ...loading,
            save: true,
        });
        try {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_HOST_API}/hotels/new`,
                {
                    name: hotel.name,
                    place: hotel.address_obj?.city,
                    hotelId: hotel.location_id,
                    searchQuery: "tripadvisor",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            setLoading({
                ...loading,
                save: false,
            });
            const hotelCode = response.data?.hotels[0].code;
            const gdsProvider =
                response.data?.hotels[0].gds_provider || "hotelbeds";
            router.push(paths.dashboard.hotel.details(gdsProvider, hotelCode));
        } catch (error) {
            console.error("Error saving hotel:", error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error("API Error:", error.response.data);
            }
            setLoading({
                ...loading,
                save: false,
            });
        }
    };

    return {
        loading,
        searchResults,
        hotelSelected,

        setHotelForm,
        setHotelSelected,
        handleSearchInfo,
        handleSaveHotel,
    };
};
